import React, {
  Fragment,
  useContext,
  forwardRef,
  useState,
  useEffect,
} from "react";

import { UrlServicesContext } from "components/UrlServicesContext";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import format from "date-fns/format";
import { Cancel, Print } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";
import axios from "axios";
import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewAssignmentsDressmakers(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [loader, setLoader] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication, urlBase } =
    useContext(UrlServicesContext);

  let data = props.id;

  const handleCloseDialog = () => {
    props.exit();
  };

  useEffect(() => {
    axios
      .get(`${urlServices}assignments/${data.id}/summary`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRows(response.data);
            setLoader(false);
          }
        },
        (error) => {
          console.log(error);
          setRows([]);
          setError(true);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization]);

  const returnRt = (data) => {
    return (
      <Fragment>
        <Paper
          style={{
            boxShadow: "unset",
          }}
        >
          <Grid container padding={1}>
            <Grid item xs={6} sm={6} style={{ padding: "0 10px" }}>
              <TableContainer>
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={4}
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 12,
                          textAlign: "center",
                        }}
                      >
                        <b>DETALLE DE CORTE</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>ITEM</b>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>TALLA</b>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>CANTIDAD</b>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>BULTO</b>
                      </TableCell>
                    </TableRow>
                    <Fragment>
                      {data.assignmentDetail.map((row) => {
                        return (
                          <Fragment>
                            <TableRow key={row.id}>
                              <TableCell
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  width: 180,
                                }}
                              >
                                <b>{row.model_name}&nbsp;{row.model_type_name}</b>
                                <br></br>{" "}
                                <span style={{ fontSize: 10 }}>
                                  {row.product_name}<br></br>
                                  <b>(Corte: {row.cut_control_id})</b>
                                </span>
                              </TableCell>
                              <TableCell
                                style={{ fontSize: 14, textAlign: "center" }}
                              >
                                <b>{row.size_name}</b>
                              </TableCell>
                              <TableCell
                                style={{ fontSize: 12, textAlign: "center" }}
                              >
                                <b>{row.quantity}</b>
                              </TableCell>
                              <TableCell
                                style={{ fontSize: 12, textAlign: "center" }}
                              >
                                <b>{row.bags}</b>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={6} sm={6} style={{ padding: "0 10px" }}>
              <TableContainer>
                <Table
                  size="small"
                  aria-label="a dense table"
                  style={{ border: "1px solid rgba(224, 224, 224, 1)" }}
                >
                  <TableBody>
                    <TableRow>
                      <TableCell
                        colSpan={2}
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 12,
                          textAlign: "center",
                        }}
                      >
                        <b>MATERIALES</b>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>DESCRIPCIÓN</b>
                      </TableCell>
                      <TableCell
                        style={{
                          backgroundColor: "#F2F2F2",
                          padding: 5,
                          fontSize: 10,
                          textAlign: "center",
                        }}
                      >
                        <b>CANTIDAD</b>
                      </TableCell>
                    </TableRow>
                    <Fragment>
                      {data.assignmentDetailSupplyMerge.map((row) => {
                        return (
                          <Fragment>
                            <TableRow key={row.id}>
                              <TableCell
                                style={{
                                  backgroundColor: "#F2F2F2",
                                  width: 180,
                                  fontSize: 12,
                                }}
                              >
                                <b>{row.confection_item_name}</b>
                              </TableCell>
                              <TableCell
                                style={{ fontSize: 12, textAlign: "center" }}
                              >
                                <b>
                                  {row.total_supply
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                                </b>
                              </TableCell>
                            </TableRow>
                          </Fragment>
                        );
                      })}
                    </Fragment>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} md={12}>
                {error ? (
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                ) : (
                  ""
                )}
                <div className={classes.titleCardFilter}>
                  Hoja de control
                </div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer style={{ marginTop: 10 }}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          <b>ID Nº:</b> {data.id}
                        </TableCell>
                        <TableCell colSpan={2}>
                          <span style={{ float: "right" }}>
                            <b>Registro:</b>{" "}
                            {format(
                              new Date(data.created_at),
                              "dd-MM-yyyy kk:mm:ss"
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={3}>
                          <b>Confeccionista:</b> {data.dressmaker_name}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>
                          <b>Total de prendas:</b> {data.total_clothing}
                        </TableCell>
                        <TableCell>
                          <b>Bultos:</b> {data.bags}
                        </TableCell>
                        <TableCell>
                          <b>Entrega:</b>{" "}
                          {format(new Date(data.deliver_at), "dd-MM-yyyy")}
                        </TableCell>
                      </TableRow>
                      {data.comments !== "" && data.comments !== null ? (
                        <TableRow>
                          <TableCell colSpan={3}>
                            <b>Observación:</b> {data.comments}
                          </TableCell>
                        </TableRow>
                      ) : (
                        ""
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              {loader ? (
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: "100%" }}>
                    <Skeleton width="100%" height={200} />
                  </Box>
                </Grid>
              ) : (
                <Fragment>                 
                  <Grid item xs={12} md={12}>
                    {returnRt(rows)}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit3}
                        href={
                          urlBase + "printAssignmentsDressmakers/" + data.id
                        }
                        startIcon={<Print />}
                        component="a"
                        target="_blank"
                      >
                        Imprimir
                      </Button>
                    </center>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
