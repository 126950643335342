/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

// core components
import Admin from "layouts/Admin.js";
import AdminUserCut from "layouts/AdminUserCut.js";
import AdminUserDressMaker from "layouts/AdminUserDressMaker.js";
import Login from "layouts/Login";
import Form from "layouts/Form";
import PrintPDFAssignmentsDressmakers from "views/Print/PrintPDFAssignmentsDressmakers";
import PrintPDFCutControl from "views/Print/PrintPDFCutControl";
import NotFoundPage from "views/404";

import "assets/css/material-dashboard-react.css?v=1.8.0";
import { UrlServicesProvider } from "components/UrlServicesContext";

const hist = createBrowserHistory();

ReactDOM.render(
  <UrlServicesProvider>
    <Router history={hist}>
      <Switch>
        <Route path="/" exact={true} component={Login} />
        <Route path="/admin" component={Admin} />
        <Route path="/adminusercut" component={AdminUserCut} />
        <Route path="/adminuserdressmaker" component={AdminUserDressMaker} />
        <Route path="/login" exact={true} component={Login} />
        <Route path="/form" exact={true} component={Form} />
        <Route
          path="/printAssignmentsDressmakers/:id"
          exact={true}
          component={PrintPDFAssignmentsDressmakers}
        />
        <Route
          path="/printcutcontrol/:id"
          exact={true}
          component={PrintPDFCutControl}
        />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Router>
  </UrlServicesProvider>,
  document.getElementById("root")
);
