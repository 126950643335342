import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import { Cancel } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewUnitsAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    name: "",
    code: "",
    errorName: false,
    errorCode: false,
  });

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorCode: false,
    });
    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.code.length === 0) {
      setValues({ ...values, errorCode: true });
      setMensaje('El campo "Código" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue = {
        name: values.name,
        code: values.code,
        catalog_id: "4",
      };

      axios
        .post(urlServices + "catalogsDetails", dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            setopenAlertSuccess(true);
            setmensaje_success("Registro guardado con éxito.");
            props.callBackRefresh();

            setValues({
              name: "",
              code: "",
            });
          },
          (error) => {
            setMensaje("Error de conexión, intente más tarde.");
            setOpenAlert(true);
            setError("error");
          }
        );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Nueva unidad</div>
                    <div className={classes.lineBottom}></div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombre o descripción"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="code"
                        value={values.code}
                        error={values.errorCode}
                        onChange={handleChange("code")}
                        fullWidth
                        required
                        label="Código"
                        margin="dense"
                        autoComplete="off"
                        helperText="Ingresar el código que desee (números o letras)."
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      {openAlertSuccess ? (
                        <Alert severity="success">{mensaje_success}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
