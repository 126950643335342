import React, { useContext, useState, useEffect, Fragment } from "react";

import { UrlServicesContext } from "components/UrlServicesContext";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import axios from "axios";
import Moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import font from "assets/font/Roboto-Bold.ttf";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    marginTop: 5,
    borderCollapse: "collapse",
  },
  tableHeader: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderCollapse: "collapse",
  },
  tableDinamic: {
    display: "table",
    borderLeftColor: "rgba(255, 255, 255, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(255, 255, 255, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
    marginTop: 25,
    borderCollapse: "collapse",
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
    borderCollapse: "collapse",
  },
  tableRowBottom: {
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(255, 255, 255, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderAll: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: "100%",
  },
  colHeader: {
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(255, 255, 255, 1)",
    borderWidth: 1,
    marginTop: "-5px",
    //width: 200,
  },
  tableCol1HeaderDinamic: {
    width: "100%",
    borderWidth: 0,
    padding: 8,
    textAlign: "center",
    backgroundColor: "rgb(242, 242, 242)",
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  tableCol2HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 350,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol1: {
    borderLeftColor: "rgba(255, 255, 255, 1)",
    borderTopColor: "rgba(255, 255, 255, 1)",
    borderRightColor: "rgba(255, 255, 255, 1)",
    borderBottomColor: "rgba(255, 255, 255, 1)",
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: "Roboto-Bold",
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#000000",
    fontFamily: "Roboto-Bold",
  },
  tableCellHeaderDinamic1: {
    fontSize: 8,
  },
  tableCellHeaderDinamic2: {
    fontSize: 10,
    width: 300,
    padding: "5px 0 0 5px",
  },
  tableCellHeaderDinamic3: {
    fontSize: 8,
    width: 305,
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    padding: "3px 0 0 90px",
    backgroundColor: "rgb(242, 242, 242)",
    fontFamily: "Roboto-Bold",
    marginTop: "-5px",
  },
  tableCellHeaderDinamic4: {
    fontSize: 8,
    width: 83,
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    padding: "3px 0 0 2px",
    backgroundColor: "rgb(242, 242, 242)",
    fontFamily: "Roboto-Bold",
    marginTop: "-5px",
  },
  tableCellHeaderDinamic5: {
    fontSize: 8,
    width: 485,
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    padding: "3px 0 0 200px",
    backgroundColor: "rgb(242, 242, 242)",
    fontFamily: "Roboto-Bold",
    marginTop: "-5px",
  },
  tableCellHeaderDinamic6: {
    fontSize: 8,
    width: 480,
    padding: "5px 0 0 5px",
  },
  tableCellHeaderDinamicData: {
    padding: 5,
    width: 80,
    textAlign: "center",
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: "#000",
    padding: 1,
    borderRadius: 10,
    width: 100,
  },
  lineTop: {
    marginTop: 5,
    backgroundColor: "#000",
    padding: "0.5",
    borderRadius: 10,
    width: 150,
  },
  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: "Roboto-Bold",
  },
  titleSub: {
    fontSize: 7,
    margin: "3px 0 0 5px",
    fontFamily: "Roboto-Bold",
  },
});

Font.register({
  family: "Roboto-Bold",
  src: font,
});
// Create Document Component
function PrintPDFAssignmentsDressmakers(props) {
  const [rows, setRows] = useState([]);
  const [rowsMain, setRowsMain] = useState("");
  const [openBackdrop, setoOpenBackdrop] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  let params = Number(props.match.params.id);

  useEffect(() => {
    axios
      .get(`${urlServices}assignments/${params}/summary`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRows(response.data);
            setoOpenBackdrop(false);
          }
        },
        (error) => {
          console.log(error);
          setRows([]);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization]);

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: "9999", color: "#FFFFFF" }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    );
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            <View style={{ marginBottom: 20 }}>
              <Text style={styles.titlePage}> Hoja de control</Text>
              <Text style={styles.lineBottom}></Text>
            </View>

            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Nº:
                    <Text style={styles.tableCell}>
                      {" "}
                      {" " + rows.assignment.id}
                    </Text>
                  </Text>
                </View>
                <View style={{ width: 150 }}></View>
                <View style={styles.tableCol1HeaderRight}>
                  <Text style={styles.tableCellHeader}>
                    Registro:
                    <Text style={styles.tableCell}>
                      {" " +
                        Moment(rows.assignment.created_at).format(
                          "DD-MM-YYYY kk:mm:ss"
                        )}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol2HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Confeccionista:
                    <Text style={styles.tableCell}>
                      {" " + rows.assignment.dressmaker_name}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow, styles.tableRowTop]}>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Total de prendas:
                    <Text style={styles.tableCell}>
                      {" " + rows.assignment.total_clothing}
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Bultos:
                    <Text style={styles.tableCell}>
                      {" " + rows.assignment.bags}
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Entrega:
                    <Text style={styles.tableCell}>
                      {" " +
                        Moment(rows.assignment.deliver_at).format("DD-MM-YYYY")}
                    </Text>
                  </Text>
                </View>
              </View>
              {rows.assignment.comments !== "" &&
              rows.assignment.comments !== null ? (
                <View style={[styles.tableRow, styles.tableRowBottom]}>
                  <View style={styles.tableCol1HeaderAll}>
                    <Text style={styles.tableCellHeader}>
                      Observación:
                      <Text style={styles.tableCell}>
                        {" " + rows.assignment.comments}
                      </Text>
                    </Text>
                  </View>
                </View>
              ) : (
                <View></View>
              )}
            </View>

            <View style={styles.tableDinamic}>
              <View style={styles.tableRowDinamic}>
                <View style={styles.tableCol1HeaderDinamic}>
                  <Text style={styles.tableCellHeaderDinamic}>
                    DETALLE DE CORTE
                  </Text>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCellHeaderDinamic3}>
                    <Text style={styles.tableCellHeaderDinamicData}>ITEM</Text>
                  </View>
                  <View style={styles.tableCellHeaderDinamic4}>
                    <Text style={styles.tableCellHeaderDinamicData}>TALLA</Text>
                  </View>
                  <View style={styles.tableCellHeaderDinamic4}>
                    <Text style={styles.tableCellHeaderDinamicData}>
                      CANTIDAD
                    </Text>
                  </View>
                  <View style={styles.tableCellHeaderDinamic4}>
                    <Text style={styles.tableCellHeaderDinamicData}>BULTO</Text>
                  </View>
                </View>
              </View>

              {rows.assignmentDetail.map((row, i) => (
                <View key={i}>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.colHeader}>
                        <Text style={styles.tableCellHeaderDinamic2}>
                          {" "}
                          {row.model_name}
                        </Text>
                        <Text style={styles.titleSub}>
                          {row.product_name} (Corte: {row.cut_control_id})
                        </Text>
                      </View>
                      <View style={styles.colHeader}>
                        <Text
                          style={[
                            styles.tableCellHeaderDinamic1,
                            styles.tableCellHeaderDinamicData,
                          ]}
                        >
                          {row.size_name}
                        </Text>
                      </View>
                      <View style={styles.colHeader}>
                        <Text
                          style={[
                            styles.tableCellHeaderDinamic1,
                            styles.tableCellHeaderDinamicData,
                          ]}
                        >
                          {row.quantity}
                        </Text>
                      </View>
                      <View style={styles.colHeader}>
                        <Text
                          style={[
                            styles.tableCellHeaderDinamic1,
                            styles.tableCellHeaderDinamicData,
                          ]}
                        >
                          {row.bags}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>

            <View style={styles.tableDinamic}>
              <View style={styles.tableRowDinamic}>
                <View style={styles.tableCol1HeaderDinamic}>
                  <Text style={styles.tableCellHeaderDinamic}>MATERIALES</Text>
                </View>
              </View>

              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={styles.tableCellHeaderDinamic5}>
                    <Text style={styles.tableCellHeaderDinamicData}>
                      DESCRIPCIÓN
                    </Text>
                  </View>
                  <View style={styles.tableCellHeaderDinamic4}>
                    <Text style={styles.tableCellHeaderDinamicData}>
                      CANTIDAD
                    </Text>
                  </View>
                </View>
              </View>

              {rows.assignmentDetailSupplyMerge.map((row, i) => (
                <View key={i}>
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <View style={styles.colHeader}>
                        <Text style={styles.tableCellHeaderDinamic6}>
                          {" "}
                          {row.confection_item_name}
                        </Text>
                      </View>
                      <View style={styles.colHeader}>
                        <Text
                          style={[
                            styles.tableCellHeaderDinamic1,
                            styles.tableCellHeaderDinamicData,
                          ]}
                        >
                          {row.total_supply
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>

            <View style={{ margin: "40px 0 25px 60px" }}>
              <View style={styles.table}>
                <View style={styles.tableRow}>
                  <View style={{ padding: 30 }}>
                    <Text style={styles.lineTop}></Text>
                    <Text style={styles.titleSub}> FIRMA CONFECCIONISTA</Text>
                  </View>
                  <View style={{ padding: 30 }}>
                    <Text style={styles.lineTop}></Text>
                    <Text style={styles.titleSub}>
                      {" "}
                      FIRMA PERSONA QUE ENTREGA
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}
export default PrintPDFAssignmentsDressmakers;
