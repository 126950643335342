import React, { Fragment, useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import { Animated } from "react-animated-css";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";
import { CheckCircle, GridOn } from "@material-ui/icons";

// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Card from "components/Card/Card.js";
import CardIcon from "components/Card/CardIcon.js";

import imguser from "assets/img/welcomeadmin.png";

import EditPasswordRecovery from "views/dialog/EditPasswordRecovery";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function DashboardAdminUserDressMaker() {
  const classes = useStyles();

  const [openDressMaker, setopenDressMaker] = useState(false);
  const [openListDressMaker, setopenListDressMaker] = useState(false);
  const [openEditPassword, setOpenEditPassword] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    if (Number(localStorage.getItem("status")) === 2) {
      setOpenEditPassword(true);
    }
  }, []);
  const handleOpenDressMaker = () => () => {
    setopenDressMaker(true);
  };

  const handleOpenListDressMaker = () => () => {
    setopenListDressMaker(true);
  };

  if (openDressMaker) {
    return <Redirect to="/adminuserdressmaker/dressmaker" />;
  }

  if (openListDressMaker) {
    return <Redirect to="/adminuserdressmaker/dressmakerlist" />;
  }

  const handleCloseOpenEditPassword = () => {
    setOpenEditPassword(false);
  };

  return (
    <Fragment>
      <GridContainer>
        <div className={classes.carDashboard}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6} style={{ marginTop: 30 }}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <div className={classes.titleCard}>
                  Bienvenido(a) al sistema administrativo
                </div>
                <br></br>
                <div className={classes.fontParagraph}>
                  Fabrimas es una empresa mayorista de textil en la ciudad de
                  Cuenca, atentos y a disposición del publico.
                </div>
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={200}
                isVisible={true}
              >
                <img src={imguser} alt="user" className={classes.cardImgUser} />
              </Animated>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Animated
                animationIn="bounceIn"
                animationInDuration={2000}
                isVisible={true}
              >
                <Card>
                  {" "}
                  <ListItem button onClick={handleOpenDressMaker()}>
                    <CardIcon color="success">
                      <CheckCircle
                        fontSize="large"
                        className={classes.iconWhite}
                      ></CheckCircle>
                    </CardIcon>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="textPrimary"
                    >
                      Asignar confeccionista
                    </Typography>
                  </ListItem>
                </Card>
              </Animated>
            </GridItem>

            <GridItem xs={12} sm={12} md={6}>
              <Animated
                animationIn="bounceIn"
                animationInDuration={2000}
                isVisible={true}
              >
                <Card>
                  {" "}
                  <ListItem button onClick={handleOpenListDressMaker()}>
                    <CardIcon color="warning">
                      <GridOn
                        fontSize="large"
                        className={classes.iconWhite}
                      ></GridOn>
                    </CardIcon>
                    <Typography
                      variant="subtitle2"
                      align="center"
                      color="textPrimary"
                    >
                      Lista de asignaciones
                    </Typography>
                  </ListItem>
                </Card>
              </Animated>
            </GridItem>
          </GridContainer>
        </div>
      </GridContainer>
      {openEditPassword ? (
        <EditPasswordRecovery
          open={openEditPassword}
          exit={handleCloseOpenEditPassword}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}
