import React, { createContext, useState } from "react";

const UrlServicesContext = createContext();
const { Provider, Consumer } = UrlServicesContext;

function UrlServicesProvider({ children }) {
  /* ### LOCAL PRUEBAS ### */
  //const [urlBase] = useState("http://localhost:3000/");
  const [urlBase] = useState(
    "https://emprendimientoec.web.economiacircular.red/"
  );

  const [urlServices] = useState(
    "https://emprendimientoec.api.economiacircular.red/"
  );

  /* ### LOAD SERVICES KEYAPLICATION */
  const [keyAplication] = useState("szdDl6aeX4efkArzKzJw");

  return (
    <Provider
      value={{
        urlServices,
        keyAplication,
        urlBase,
      }}
    >
      {children}
    </Provider>
  );
}

export {
  UrlServicesProvider,
  Consumer as urlServicesConsumer,
  UrlServicesContext,
};
