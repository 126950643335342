import React, {
  Fragment,
  useContext,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import format from "date-fns/format";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import { Cancel } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";

import img from "assets/img/undraw_handcrafts_travel.png";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

function createData(name, data) {
  return { name, data };
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewProductCompleteAdmin(props) {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [rowsModels, setRowsModels] = useState([]);

  let data = props.data;

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication, urlLocalMediaProducts } =
    useContext(UrlServicesContext);

  const rows = [
    createData("Nombre", data.name),
    createData("Detalle", data.detail),
    createData("Código", data.code),
    createData("Tipo producto", data.product_type_name),
    createData(
      "Registrado",
      format(new Date(data.created_at), "dd-MM-yyyy kk:mm:ss")
    ),
  ];

  useEffect(() => {
    axios
      .get(`${urlServices}products-models/product/${data.id}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRowsModels(response.data);
            setLoader(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization]);

  const listItemsModel = () => {
    const numRows = rowsModels.length;
    let output = [];

    rowsModels.map((items, i) => {
      output.push(items.model_name + "(" + items.model_type_name + ")");

      if (i < numRows - 1) {
        output.push(", ");
      } else {
        output.push(".");
      }
      return output;
    });

    return output;
  };

  const handleCloseDialog = () => {
    props.exit();
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2} style={{ padding: 10 }}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Registro</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} md={12}>
                <center>
                  <img
                    alt="avatar"
                    src={
                      data.picture === null || data.picture === ""
                        ? img
                        : urlLocalMediaProducts + data.picture
                    }
                    className={classes.imgUserProfile}
                  />
                </center>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <b>{row.name}</b>
                          </TableCell>
                          <TableCell>{row.data}</TableCell>
                        </StyledTableRow>
                      ))}
                      <TableRow hover={true}>
                        <TableCell
                          component="th"
                          scope="row"
                          className={classes.tr}
                        >
                          <b>Modelos</b>
                        </TableCell>
                        <TableCell className={classes.th}>
                          {loader ? (
                            <Grid container spacing={2}>
                              <Grid item xs={12} sm={12}>
                                <Box sx={{ width: "100%" }}>
                                  <Skeleton width="100%" height={40} />
                                </Box>
                              </Grid>
                            </Grid>
                          ) : (
                            <Fragment> {listItemsModel()} </Fragment>
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
