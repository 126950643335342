import React, { Fragment } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import successImg from "assets/img/success.gif";
import { Cancel } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function SuccessfulMessageForm(props) {
  const classes = useStyles();

  const handleClose = () => {
    props.callbackFalse(false);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        //onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.callbackTrue}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers style={{ padding: 50 }}>
          <center>
            <img src={successImg} style={{ width: "100px" }} alt="confirmado" />
          </center>
          <Typography variant="h6" align="center">
            <b style={{ color: "green" }}>Registro realizado con éxito</b>
          </Typography>
          <br></br>
          <Typography variant="inherit">
            <center>
              <b>{props.mensaje_success}</b>
            </center>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
