/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { Remove } from "@material-ui/icons";

// core components/views for Admin layout
import DashboardAdminUser from "views/Dashboard/DashboardAdminUserCut";
import FormProfile from "views/AdminUserCut/FormProfile";
import WeeklyListAdmin from "views/Admin/WeeklyListAdmin.js";

const dashboardRoutes = [
  {
    id: 0,
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: DashboardAdminUser,
    layout: "/adminusercut",
    submenu: [],
  },
  /* START CONTROL DE CORTE  */
  {
    id: 1,
    subId: "",
    path: "",
    name: "Control de corte",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/adminusercut",
    submenu: [
      {
        subId: 1,
        name: "Pedido semanal",
        path: "/weekly",
      },
     
    ],
  },
  {
    // SUBMENU PEDIDOS SEMANALES
    id: "",
    subId: 1,
    path: "/weekly",
    name: "",
    rtlName: "",
    icon: "",
    component: WeeklyListAdmin,
    layout: "/adminusercut",
    submenu: [],
  },


  {
    id: 0,
    path: "/profile",
    name: "Mi perfil",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: FormProfile,
    layout: "/adminusercut",
    submenu: [],
  },
];

export default dashboardRoutes;
