import React, { Fragment, forwardRef, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import { Cancel } from "@material-ui/icons";
import format from "date-fns/format";

import img from "assets/img/undraw_handcrafts_user.png";

import Slide from "@material-ui/core/Slide";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewUserAdmin(props) {
  const classes = useStyles();

  const { urlLocalMediaProfile } = useContext(UrlServicesContext);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  function createData(name, data) {
    return { name, data };
  }

  const rows = [
    createData(
      "Estatus",
      props.id.status === 1
        ? "ACTIVO"
        : props.id.status === 2
        ? "PENDIENTE "
        : "INACTIVO"
    ),
    createData("Nombre", props.id.name + " " + props.id.last_name),
    createData("Email", props.id.email),
    createData("Documento", props.id.document_id),
    createData("Teléfono", props.id.phone),
    createData("Dirección", props.id.address),
    createData("Username", props.id.username),
    createData(
      "Registrado",
      format(new Date(props.id.created_at), "dd-MM-yyyy kk:mm:ss")
    ),
  ];

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <center>
                  <img
                    alt="avatar"
                    src={
                      props.id.picture === null
                        ? img
                        : urlLocalMediaProfile + props.id.picture
                    }
                    className={classes.imgUserProfile}
                  />
                  <div className={classes.cardTitleDialog}>
                    {props.id.name +
                      " " +
                      (props.id.last_name === null ? "" : props.id.last_name)}
                  </div>
                  <div className={classes.cardSubTitle}>
                    <b>({props.id.profileName})</b>
                  </div>
                </center>
              </Grid>
            </Grid>
            <br></br>
            <div className={classes.cardBrancheViewTitle}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <TableContainer style={{ marginTop: 10 }}>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        {rows.map((row, key) => (
                          <StyledTableRow key={key}>
                            <TableCell component="th" scope="row">
                              <b>{row.name}</b>
                            </TableCell>
                            <TableCell>{row.data}</TableCell>
                          </StyledTableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </div>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
