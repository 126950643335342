// @material-ui/icons
import { Remove } from "@material-ui/icons";

// PAGES
import DashboardPage from "views/Dashboard/Dashboard.js";
import UserListAdmin from "views/Admin/UserListAdmin.js";
import ProfilesListAdmin from "views/Admin/ProfilesListAdmin.js";
import ProductsListAdmin from "views/Admin/ProductsListAdmin.js";
import UnitsListAdmin from "views/Admin/UnitsListAdmin.js";
import SizesListAdmin from "views/Admin/SizesListAdmin.js";
import ModelTypesListAdmin from "views/Admin/ModelTypesListAdmin.js";
import ConfectionListAdmin from "views/Admin/ConfectionListAdmin.js";
import ModelListAdmin from "views/Admin/ModelListAdmin.js";
import ProductsCompleteListAdmin from "views/Admin/ProductsCompleteListAdmin.js";
import CompositionsListAdmin from "views/Admin/CompositionsListAdmin.js";
import SizesModelsTypesListAdmin from "views/Admin/SizesModelsTypesListAdmin.js";
import WeeklyListAdmin from "views/Admin/WeeklyListAdmin.js";
import AssignmentsDressmakersList from "views/Admin/AssignmentsDressmakersList.js";
import ReceptionDressmakersList from "views/Admin/ReceptionDressmakersList.js";

const dashboardRoutes = [
  {
    id: 0,
    subId: "",
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: DashboardPage,
    layout: "/admin",
    submenu: [],
  },
  /* START GESTION */
  {
    id: 1,
    subId: "",
    path: "",
    name: "Gestión",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/admin",
    submenu: [
      {
        subId: 1,
        name: "Perfiles",
        path: "/profilelist",
      },
      {
        subId: 1,
        name: "Tipos Productos",
        path: "/productlist",
      },
      {
        subId: 1,
        name: "Unidades",
        path: "/unitslist",
      },
      {
        subId: 1,
        name: "Tallas",
        path: "/sizeslist",
      },
      {
        subId: 1,
        name: "Tipos modelos",
        path: "/modeltypeslist",
      },
      {
        subId: 1,
        name: "Colores",
        path: "/colorslist",
      },
    ],
  },
  {
    // SUBMENU Profiles list
    id: "",
    subId: 1,
    path: "/profilelist",
    name: "",
    rtlName: "",
    icon: "",
    component: ProfilesListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    // SUBMENU Products list
    id: "",
    subId: 1,
    path: "/productlist",
    name: "",
    rtlName: "",
    icon: "",
    component: ProductsListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    // SUBMENU Units list
    id: "",
    subId: 1,
    path: "/unitslist",
    name: "",
    rtlName: "",
    icon: "",
    component: UnitsListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    // SUBMENU Units list
    id: "",
    subId: 1,
    path: "/sizeslist",
    name: "",
    rtlName: "",
    icon: "",
    component: SizesListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    // SUBMENU Model types list
    id: "",
    subId: 1,
    path: "/modeltypeslist",
    name: "",
    rtlName: "",
    icon: "",
    component: ModelTypesListAdmin,
    layout: "/admin",
    submenu: [],
  },
 
  /* END GESTION */
  {
    id: 0,
    subId: "",
    path: "/sizesmodelstypeslist",
    name: "Tallas-Modelos",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: SizesModelsTypesListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/confectionlist",
    name: "Insumos",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: ConfectionListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/modellist",
    name: "Modelos",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: ModelListAdmin,
    layout: "/admin",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/products",
    name: "Productos",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: ProductsCompleteListAdmin,
    layout: "/admin",
    submenu: [],
  },
  /* START CONTROL DE CORTE  */
  {
    id: 2,
    subId: "",
    path: "",
    name: "Control de corte",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/admin",
    submenu: [
      {
        subId: 2,
        name: "Pedido semanal",
        path: "/weekly",
      },
    ],
  },
  {
    // SUBMENU PEDIDOS SEMANALES
    id: "",
    subId: 2,
    path: "/weekly",
    name: "",
    rtlName: "",
    icon: "",
    component: WeeklyListAdmin,
    layout: "/admin",
    submenu: [],
  },
 
 
  /* END CONTROL DE CORTE */
  /* START ASSIGNMENTS DRESSMAKERS DE CORTE  */
  {
    id: 3,
    subId: "",
    path: "",
    name: "Confeccionista",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/admin",
    submenu: [
      {
        subId: 3,
        name: " Asignación insumos",
        path: "/compositionslist",
      },
      {
        subId: 3,
        name: "Asignación confeccionista",
        path: "/dressmaker",
      },
      {
        subId: 3,
        name: "Listado Asignación",
        path: "/dressmakerlist",
      },
      {
        subId: 3,
        name: "Recepción",
        path: "/receptions",
      },
      {
        subId: 3,
        name: "Listado Recepción",
        path: "/receptionslist",
      },
    ],
  },
  {
    // SUBMENU CONTROL DE CORTE ASIGNACION CONFECCIONISTA

    id: "",
    subId: 3,
    path: "/compositionslist",
    name: "",
    rtlName: "",
    icon: "",
    component: CompositionsListAdmin,
    layout: "/admin",
    submenu: [],
  },

  {
    // SUBMENU ASIGNACION CONFECCIONISTA LIST
    id: "",
    subId: 3,
    path: "/dressmakerlist",
    name: "",
    rtlName: "",
    icon: "",
    component: AssignmentsDressmakersList,
    layout: "/admin",
    submenu: [],
  },

  {
    // SUBMENU RECEPTION LIST
    id: "",
    subId: 3,
    path: "/receptionslist",
    name: "",
    rtlName: "",
    icon: "",
    component: ReceptionDressmakersList,
    layout: "/admin",
    submenu: [],
  },
  /* END ASSIGNMENTS DRESSMAKERS */
  {
    id: 0,
    subId: "",
    path: "/users",
    name: "Usuarios",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: UserListAdmin,
    layout: "/admin",
    submenu: [],
  },
];

export default dashboardRoutes;
