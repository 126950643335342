import React, { Component } from "react";
import Geocode from "react-geocode";
import { geolocated } from "react-geolocated";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import CircularProgress from "@material-ui/core/CircularProgress";

Geocode.setApiKey("AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o");

class GoogleMap extends Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: "",
    markerCoordinate: {
      lat: this.props.dataltlg.lt,
      lng: this.props.dataltlg.lg,
    },
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onMapReady = () => {
    this.setState({
      markerCoordinate: {
        lat: this.props.coords.latitude,
        lng: this.props.coords.longitude,
      },
    });

    this.getAddressfromCoordinates(
      this.state.markerCoordinate.lat,
      this.state.markerCoordinate.lng
    );
  };

  onMarkerDragEnd = (coord) => {
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({
      ...this.state,
      markerCoordinate: {
        lat: this.props.coords.latitude,
        lng: this.props.coords.longitude,
      },
    });
    this.getAddressfromCoordinates(lat, lng);

    this.props.callbacklt(lat);
    this.props.callbacklng(lng);
  };

  getAddressfromCoordinates = (lat, lng) => {
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const address = response.results[0].formatted_address;
        this.setState({ selectedPlace: address });
      },
      (error) => {
        console.error(error);
      }
    );
  };
  change = (center) => {
    //console.log(center);
  };

  render() {
    let body = <CircularProgress />;

    if (this.props.coords) {
      body = (
        <Map
          ref="map"
          google={this.props.google}
          zoom={14}
          onReady={this.onMapReady}
          initialCenter={{
            /*lat: this.props.coords.latitude,
            lng: this.props.coords.longitude,*/
            lat: this.state.markerCoordinate.lat,
            lng: this.state.markerCoordinate.lng,
          }}
          onMouseover={(t, map, e) => this.change(e)}
          onClick={this.onMapClicked}
        >
          <Marker
            draggable
            animation={this.props.google.maps.Animation.DROP}
            onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
            onClick={this.onMarkerClick}
          ></Marker>
          <InfoWindow
            marker={this.state.activeMarker}
            visible={this.state.showingInfoWindow}
            onClose={this.onMapClicked}
          >
            <div>
              <h1 style={{ fontSize: "10px" }}>{this.state.selectedPlace}</h1>
            </div>
          </InfoWindow>
        </Map>
      );
    }
    return (
      <div
        className="App"
        style={{ position: "relative", height: 380, flexWrap: "inherit" }}
      >
        {body}
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDy8AE5RqF-ozGiI8J7r-U1vnb3AwQCY-o",
  libraries: ["visualization"],
})(
  geolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  })(GoogleMap)
);
