import React, { useState, useContext, Fragment } from "react";
import { Redirect } from "react-router-dom";

import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
// core components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Input from "@material-ui/core/Input";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff, ArrowRightAlt } from "@material-ui/icons";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { Animated } from "react-animated-css";
import LinearProgress from "@material-ui/core/LinearProgress";
import axios from "axios";

import mail from "assets/img/userlogin.png";
import passImg from "assets/img/passlogin.png";

import PasswordRecovery from "./dialog/PasswordRecovery";

import {
  cardBodyStyle,
  progressLinear,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);
const ColorLinearProgress = withStyles(progressLinear)(LinearProgress);

export default function Login() {
  const classes = useStyles();
  const [loaderLogin, setLoaderLogin] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [passwordRecovery, setpasswordRecovery] = useState(false);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [errorFieldLogin, setErrorFieldLogin] = useState(false);

  const [values, setValues] = useState({
    user: "",
    password: "",
    newPassword: "",
    showPassword: false,
  });

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [alert, setAlert] = useState({
    openAlert: false,
    mensaje_error: "",
    loggedIn: localStorage.getItem("Session") === null ? false : true,
  });

  const handleChange = (prop) => (event) => {
    setErrorCorreo(false);
    setErrorFieldLogin(false);
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitEmail = () => (event) => {
    event.preventDefault();
    setLoaderLogin(true);

    if (values.user.length === 0) {
      setLoaderLogin(false);
      setErrorCorreo(true);
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "Correo electrónico es requerido.",
      });
    } else {
      let data = {
        email: values.user.toLowerCase().trim(),
      };

      axios
        .post(urlServices + "users/validateEmail", data, {
          headers: {
            application: keyAplication,
          },
        })
        .then(
          (response) => {
            if (!response.data.resCode) {
              setTimeout(() => {
                setLoaderLogin(false);
                setAlert({
                  ...alert,
                  openAlert: true,
                  mensaje_error: "Correo electrónico no registrado.",
                });
              }, 200);
            } else {
              setTimeout(() => {
                setLoaderLogin(false);
                setValidateEmail(true);
              }, 200);
            }
          },
          (error) => {
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Error de conexión, intente más tarde.",
              });
            }, 200);
          }
        );
    }
  };

  const submitFormLogin = () => (event) => {
    event.preventDefault();

    setErrorFieldLogin(false);
    setLoaderLogin(true);

    if (values.password.length === 0) {
      setErrorFieldLogin(true);
      setLoaderLogin(false);
      setAlert({
        ...alert,
        openAlert: true,
        mensaje_error: "El campo contraseña es obligatorio!",
      });
    } else {
      const data = {
        email: values.user.toLowerCase().trim(),
        password: values.password,
      };

      axios
        .post(urlServices + "sessions/login", data, {
          headers: {
            application: keyAplication,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setLoaderLogin(false);
            localStorage.setItem("status", response.data.user.status);
            localStorage.setItem("Session", response.data.jwt);
            localStorage.setItem("user", response.data.user.name);
            localStorage.setItem("id", response.data.user.id);
            localStorage.setItem(
              "profile_id",
              response.data.user.profile_id === ""
                ? 0
                : response.data.user.profile_id
            );
            localStorage.setItem(
              "allname",
              response.data.user.name.charAt(0).toUpperCase() +
                response.data.user.name.slice(1)
            );

            redirectProgile(response.data.user.profile_id);
          }
        })
        .catch((e) => {
          if (e.response.status === 401) {
            setLoaderLogin(false);
            setValidateEmail(false);
            setValues({ ...values, password: "" });
            setAlert({
              ...alert,
              openAlert: true,
              mensaje_error: "Contraseña incorrecta!",
            });
          } else {
            setTimeout(() => {
              setLoaderLogin(false);
              setAlert({
                ...alert,
                openAlert: true,
                mensaje_error: "Error de conexión, intente más tarde.",
              });
            }, 200);
            console.log(e);
          }
        });
    }
  };

  const handleCloseAlert = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setAlert({ openAlert: false });
  };

  const handleOpenPasswordRecovery = () => {
    setpasswordRecovery(true);
  };

  const handleClosePasswordRecovery = () => {
    setpasswordRecovery(false);
  };

  const redirectProgile = (profile) => {
    if (profile === "1") {
      localStorage.setItem("controller", "/admin");
      localStorage.setItem("controllerRouter", "/admin/dashboard");

      setAlert({ ...alert, loggedIn: true });
    } else if(profile === "10"){
      localStorage.setItem("controller", "/adminuserdressmaker");
      localStorage.setItem("controllerRouter", "/adminuserdressmaker/dashboard");

      setAlert({ ...alert, loggedIn: true });
    } else if(profile === "8"){
      localStorage.setItem("controller", "/adminusercut");
      localStorage.setItem("controllerRouter", "/adminusercut/dashboard");

      setAlert({ ...alert, loggedIn: true });
    }
  };

  if (alert.loggedIn) {
    return (
      <Redirect
        from={localStorage.getItem("controller")}
        to={localStorage.getItem("controllerRouter")}
      />
    );
  }

  return (
    <Fragment>
      <Card style={{ marginTop: "10%" }}>
        {loaderLogin ? <ColorLinearProgress /> : ""}

        <CardHeader>
          <center>
            {" "}
            <div className={classes.titleCard}>Iniciar sesión</div>
            <div className={classes.lineBottom}></div>
          </center>
        </CardHeader>
        <CardBody>
          <div style={{ overflow: "hidden" }}>
            {!validateEmail ? (
              <Animated
                animationIn="slideInLeft"
                animationInDuration={500}
                isVisible={true}
              >
                <Grid container style={loaderLogin ? { opacity: "0.4" } : null}>
                  <Grid item xs={12} sm={6}>
                    <center>
                      <img
                        src={mail}
                        alt="validate"
                        className={classes.cardImgLoginMail}
                      />
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <form onSubmit={submitEmail()}>
                      <center>
                        <TextField
                          autoComplete="off"
                          name="user"
                          value={values.user}
                          onChange={handleChange("user")}
                          label="Correo electrónico"
                          error={errorCorreo}
                          className={classes.textField}
                          fullWidth
                        />
                        <br></br>
                        <br></br>
                        <Button
                          className={classes.buttonSubmit}
                          onClick={submitEmail()}
                          size="large"
                          endIcon={<ArrowRightAlt />}
                        >
                          Validar
                        </Button>
                      </center>
                    </form>
                  </Grid>
                </Grid>
              </Animated>
            ) : (
              <Grid
                container
                spacing={1}
                style={loaderLogin ? { opacity: "0.4" } : null}
              >
                <Grid item xs={12} sm={6}>
                  <Animated
                    animationIn="slideInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <center>
                      <img
                        src={passImg}
                        alt="validate"
                        className={classes.cardImgLoginPass}
                      />
                    </center>
                  </Animated>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Animated
                    animationIn="slideInLeft"
                    animationInDuration={500}
                    isVisible={true}
                  >
                    <form onSubmit={submitFormLogin()}>
                      <center>
                        <FormControl className={classes.textField}>
                          <InputLabel htmlFor="standard-adornment-password">
                            Contraseña
                          </InputLabel>
                          <Input
                            autoFocus
                            name="password"
                            type={values.showPassword ? "text" : "password"}
                            value={values.password}
                            onChange={handleChange("password")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                >
                                  {values.showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            error={errorFieldLogin}
                          />
                        </FormControl>
                        <br></br>
                        <br></br>
                        <Button
                          className={classes.buttonSubmit}
                          onClick={submitFormLogin()}
                          color="primary"
                          size="large"
                        >
                          Iniciar sesión
                        </Button>
                      </center>
                    </form>
                  </Animated>
                </Grid>

                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <center>
                      <Button
                        className={classes.textBotom}
                        aria-haspopup="true"
                        onClick={handleOpenPasswordRecovery}
                        style={loaderLogin ? { opacity: "0.4" } : null}
                      >
                        Recuperar contraseña
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </CardBody>
        <CardFooter></CardFooter>
      </Card>

      {alert.openAlert ? (
        <Snackbar
          open={true}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseAlert}
        >
          <Slide direction="up" in={true} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleCloseAlert}
              severity="error"
              elevation={6}
              variant="filled"
            >
              {alert.mensaje_error}
            </Alert>
          </Slide>
        </Snackbar>
      ) : (
        ""
      )}

      {passwordRecovery ? (
        <PasswordRecovery
          open={passwordRecovery}
          exit={handleClosePasswordRecovery}
        />
      ) : (
        ""
      )}
    </Fragment>
  );
}
