import React, { useContext, useState, useEffect, Fragment } from "react";

import { UrlServicesContext } from "components/UrlServicesContext";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Font,
} from "@react-pdf/renderer";
import axios from "axios";
import Moment from "moment";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import font from "assets/font/Roboto-Bold.ttf";

const styles = StyleSheet.create({
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight,
  },
  body: {
    padding: 25,
  },
  table: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    marginTop: 5,
  },
  tableHeader: {
    display: "table",
    width: "100%",
    borderWidth: 0,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableDinamic: {
    display: "table",
    borderLeftColor: "rgba(255, 255, 255, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(255, 255, 255, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
    marginTop: 25,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    width: "100%",
  },
  tableRowTop: {
    marginTop: 20,
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
  },
  tableRowBottom: {
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(255, 255, 255, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(224, 224, 224, 1)",
    borderWidth: 1,
    width: "100%",
  },
  tableCol1Header: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 200,
  },
  tableCol1HeaderAll: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: "100%",
  },
  colHeader: {
    borderLeftColor: "rgba(224, 224, 224, 1)",
    borderTopColor: "rgba(224, 224, 224, 1)",
    borderRightColor: "rgba(224, 224, 224, 1)",
    borderBottomColor: "rgba(255, 255, 255, 1)",
    borderWidth: 1,
    width: 150,
  },
  tableCol1HeaderDinamic: {
    width: "100%",
    borderWidth: 0,
    padding: 8,
    textAlign: "center",
    backgroundColor: "rgb(242, 242, 242)",
  },
  tableCol1HeaderLeft: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 250,
  },
  tableCol1HeaderRight: {
    borderWidth: 0,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    width: 150,
  },
  tableCol1: {
    borderLeftColor: "rgba(255, 255, 255, 1)",
    borderTopColor: "rgba(255, 255, 255, 1)",
    borderRightColor: "rgba(255, 255, 255, 1)",
    borderBottomColor: "rgba(255, 255, 255, 1)",
    borderWidth: 1,
  },
  tableCol: {
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 10,
    fontWeight: 500,
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
    fontFamily: "Roboto-Bold",
  },
  tableCellHeaderDinamic: {
    fontSize: 10,
    fontWeight: "bold",
    color: "#000000",
    fontFamily: "Roboto-Bold",
  },
  tableCellHeaderDinamic1: {
    fontSize: 10,
  },
  tableCellHeaderDinamicData: {
    padding: 5,
    width: 150,
    textAlign: "center",
  },
  lineBottom: {
    marginTop: 5,
    backgroundColor: "#000",
    padding: 1,
    borderRadius: 10,
    width: 100,
  },
  titlePage: {
    fontSize: 14,
    marginBottom: 5,
    fontFamily: "Roboto-Bold",
  },
});

Font.register({
  family: "Roboto-Bold",
  src: font,
});

function PrintPDFCutControl(props) {
  const [rowsMain, setRowsMain] = useState([]);
  const [cutControlDetail, setCutControlDetail] = useState([]);
  const [cutControlSize, setCutControlSize] = useState([]);
  const [openBackdrop, setoOpenBackdrop] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  let params = Number(props.match.params.id);

  useEffect(() => {
    axios
      .get(`${urlServices}cut-controls/${params}/summary`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            axios
              .get(`${urlServices}cut-controls/${params}`, {
                headers: {
                  application: keyAplication,
                  Authorization: "Bearer " + keyAuthorization,
                },
              })
              .then(
                (response2) => {
                  setRowsMain(response2.data);
                  setCutControlDetail(response.data.cutControlDetail);
                  setCutControlSize(response.data.cutControlSize);
                  setoOpenBackdrop(false);
                },
                (error) => {
                  console.log(error);
                }
              );
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization, params]);

  if (openBackdrop) {
    return (
      <Fragment>
        <Backdrop
          style={{ zIndex: "9999", color: "#FFFFFF" }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Fragment>
    );
  } else {
    return (
      <PDFViewer style={styles.viewer}>
        <Document>
          <Page style={styles.body} size="A4" wrap>
            <View style={{ marginBottom: 20 }}>
              <Text style={styles.titlePage}> Hoja de control de corte</Text>
              <Text style={styles.lineBottom}></Text>
            </View>

            <View style={styles.tableHeader}>
              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Hoja de corte Nº:
                    <Text style={styles.tableCell}> {" " + rowsMain.id}</Text>
                  </Text>
                </View>
                <View style={{ width: 150 }}></View>
                <View style={styles.tableCol1HeaderRight}>
                  <Text style={styles.tableCellHeader}>
                    Registro:
                    <Text style={styles.tableCell}>
                      {" " +
                        Moment(rowsMain.created_at).format(
                          "DD-MM-YYYY kk:mm:ss"
                        )}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Pedido:
                    <Text style={styles.tableCell}>
                      {" (Nº" +
                        rowsMain.weekly_order_id +
                        ") " +
                        rowsMain.weekly_order_description}
                    </Text>
                  </Text>
                </View>
                <View style={{ width: 300 }}></View>
              </View>

              <View style={styles.tableRow}>
                <View style={styles.tableCol1HeaderLeft}>
                  <Text style={styles.tableCellHeader}>
                    Cortador:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.user_name}
                    </Text>
                  </Text>
                </View>
                <View style={{ width: 300 }}></View>
              </View>

              <View style={[styles.tableRow, styles.tableRowTop]}>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Metros tendidos:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.used_meters}
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Peso total:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.total_weight + " Kg"}
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Desperdicios:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.waste_meters + " Kg"}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow, styles.tableRowBottom]}>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Retazos:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.scraps + " Kg"}
                    </Text>
                  </Text>
                </View>
                <View style={styles.tableCol1Header}>
                  <Text style={styles.tableCellHeader}>
                    Prendas:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.total_clothing}
                    </Text>
                  </Text>
                </View>
              </View>

              <View style={[styles.tableRow, styles.tableRowBottom]}>
                <View style={styles.tableCol1HeaderAll}>
                  <Text style={styles.tableCellHeader}>
                    Observaciones:
                    <Text style={styles.tableCell}>
                      {" " + rowsMain.comments}
                    </Text>
                  </Text>
                </View>
              </View>
            </View>

            <View style={styles.tableDinamic}>
              {cutControlDetail.map((row, i) => (
                <View key={i}>
                  <View style={styles.tableRowDinamic}>
                    <View style={styles.tableCol1HeaderDinamic}>
                      <Text style={styles.tableCellHeaderDinamic}>
                        {" "}
                        {row.product_name +
                          " | " +
                          row.model_name +
                          " (" +
                          row.model_type_name +
                          ")"}
                      </Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCol1}>
                      <Text style={{ fontSize: 8, margin: 5 }}>
                        TOTALES DE PESOS, BULTOS Y CAPAS POR TALLAS:
                      </Text>

                      <View style={styles.table}>
                        <View style={styles.tableRow}>
                          <View style={styles.colHeader}>
                            <Text
                              style={[
                                styles.tableCellHeaderDinamic1,
                                styles.tableCellHeaderDinamicData,
                              ]}
                            >
                              Talla
                            </Text>
                          </View>
                          <View style={styles.colHeader}>
                            <Text
                              style={[
                                styles.tableCellHeaderDinamic1,
                                styles.tableCellHeaderDinamicData,
                              ]}
                            >
                              Pesos Kg
                            </Text>
                          </View>
                          <View style={styles.colHeader}>
                            <Text
                              style={[
                                styles.tableCellHeaderDinamic1,
                                styles.tableCellHeaderDinamicData,
                              ]}
                            >
                              Bultos
                            </Text>
                          </View>
                          <View style={styles.colHeader}>
                            <Text
                              style={[
                                styles.tableCellHeaderDinamic1,
                                styles.tableCellHeaderDinamicData,
                              ]}
                            >
                              Prendas (Capas)
                            </Text>
                          </View>
                        </View>

                        {cutControlSize.map((row3, i3) => {
                          return (
                            <View style={styles.tableRow}>
                              {row.id === row3.cut_control_detail_id ? (
                                <View style={styles.tableRow}>
                                  <View style={styles.colHeader}>
                                    <Text
                                      style={[
                                        styles.tableCellHeaderDinamic,
                                        styles.tableCellHeaderDinamicData,
                                      ]}
                                    >
                                      {" "}
                                      {row3.size_name}
                                    </Text>
                                  </View>
                                  <View style={styles.colHeader}>
                                    <Text
                                      style={[
                                        styles.tableCellHeaderDinamic,
                                        styles.tableCellHeaderDinamicData,
                                      ]}
                                    >
                                      {" "}
                                      {row3.total_weights}
                                    </Text>
                                  </View>
                                  <View style={styles.colHeader}>
                                    <Text
                                      style={[
                                        styles.tableCellHeaderDinamic,
                                        styles.tableCellHeaderDinamicData,
                                      ]}
                                    >
                                      {" "}
                                      {row3.total_bags}
                                    </Text>
                                  </View>
                                  <View style={styles.colHeader}>
                                    <Text
                                      style={[
                                        styles.tableCellHeaderDinamic,
                                        styles.tableCellHeaderDinamicData,
                                      ]}
                                    >
                                      {" "}
                                      {row3.total_layers}
                                    </Text>
                                  </View>
                                </View>
                              ) : (
                                <Text></Text>
                              )}
                            </View>
                          );
                        })}
                      </View>
                    </View>
                  </View>
                </View>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );
  }
}
export default PrintPDFCutControl;
