import React, { Fragment, useState, useEffect, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import {
  makeStyles,
  withStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Alert from "@material-ui/lab/Alert";
import Checkbox from "@material-ui/core/Checkbox";
import Hidden from "@material-ui/core/Hidden";
import Skeleton from "@material-ui/lab/Skeleton";
import axios from "axios";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import StepConnector from "@material-ui/core/StepConnector";
import FormLabel from "@material-ui/core/FormLabel";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  CheckBoxOutlineBlank,
  NavigateNext,
  NavigateBefore,
  Today,
  CheckBox,
  Reorder,
  Pageview,
  Place,
  GpsFixed,
} from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import Moment from "moment";
import InputLabel from "@material-ui/core/InputLabel";
import { Animated } from "react-animated-css";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Tooltip from "@material-ui/core/Tooltip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import format from "date-fns/format";

// SELECT
import LoadProvince from "components/services/LoadProvince";
import LoadCities from "components/services/LoadCities";
import LoadGender from "components/services/LoadGender";
import LoadSteps from "components/services/LoadSteps";
import LoadTrainingProcess from "components/services/LoadTrainingProcess";
import LoadEducationCenter from "components/services/LoadEducationCenter";
import LoadEstimatedAmount from "components/services/LoadEstimatedAmount";
import LoadGrowthResources from "components/services/LoadGrowthResources";
import LoadSuppliers from "components/services/LoadSuppliers";
import LoadAnnualBilling from "components/services/LoadAnnualBilling";
import LoadAnnualBilling2 from "components/services/LoadAnnualBilling2";
import LoadProjectEmployees from "components/services/LoadProjectEmployees";
import LoadProjectEmployees2 from "components/services/LoadProjectEmployees2";
import LoadInvestmentAmount from "components/services/LoadInvestmentAmount";
import LoadInvestmentAmount2 from "components/services/LoadInvestmentAmount2";
import LoadInvestmentAmount3 from "components/services/LoadInvestmentAmount3";
import LoadCatPartners from "components/services/LoadCatPartners";

import MapDraggableMarker from "views/Maps/MapDraggableMarker";
import SuccessfulMessage from "views/dialog/SuccessfulMessageForm";
import ResourcesData from "views/dialog/ResourcesData";
import ResourcesDataNeeds from "views/dialog/ResourcesDataNeeds";

import {
  cardBodyStyle,
  ColorConnector,
  themeCss,
  HtmlTooltipCss,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);
const ColorlibConnector = withStyles(ColorConnector)(StepConnector);
const defaultMaterialTheme = createTheme(themeCss);

const icon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;
const HtmlTooltip = withStyles(HtmlTooltipCss)(Tooltip);

export default function FormIntercambia() {
  const classes = useStyles();

  const [loader, setIsLoaded] = useState(true);
  const [catProjectType, setCatProjectType] = useState([]);
  const [catSectores, setCatSectores] = useState([]);
  const [catCommunity, setCatCommunity] = useState([]);
  const [has, setHas] = useState([]);
  const [needs, setNeeds] = useState([]);
  const [billingShow, setBillingShow] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const [catSelect, setCatSelect] = useState(false);
  const [catSelectValue, setCatSelectValue] = useState("");
  const [dataCat, setdataCat] = useState("");
  const [catSelectNeeds, setCatSelectNeeds] = useState(false);
  const [catSelectValueNeeds, setCatSelectValueNeeds] = useState("");
  const [dataCatNeeds, setdataCatNeeds] = useState("");

  const [openAlertsuccess, setOpenAlertsuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje_error, setMensaje_error] = useState("");
  const [mensaje_success, setMensaje_success] = useState("");
  const [openBackdrop, setoOpenBackdrop] = useState(false);

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    names: "",
    lastNames: "",
    document: "",
    email: "",
    studySite: "",
    career: "",
    graduationYear: null,
    businessName: "",
    descriptionbusiness: "",
    startactivities: null,
    latitude: -0.18093079060265435,
    longitude: -78.46778869628906,
    province: "",
    provinceName: "",
    disabledCity: true,
    city: "",
    cityValue: null,
    provinceValue: "",
    gender: "",
    genderData: "",
    catProjectTypeValue: [],
    catSectoresValue: [],
    steps: "",
    stepsData: "",
    numberPartners: "",
    trainingProcessData: "",
    trainingProcess: "",
    catCommunityValue: [],
    educationCenterData: "",
    educationCenter: "",
    billing: "",
    billingYears: "",
    numberEmployees: "",
    hasValue: [],
    hasValueView: [],
    needsValue: [],
    needsValueView: [],
    needPartners: "",
    needPartnersData: "",
    needPartnersView: [],
    annualBillingData: "",
    annualBilling: "",
    annualBillingData2: "",
    annualBilling2: "",
    projectEmployees: "",
    projectEmployeesData: "",
    projectEmployees2: "",
    projectEmployeesData2: "",
    investmentAmount: "",
    investmentAmountData: "",
    exportSalesPastYear: "",
    exportedCountry: "",
    investmentAmount2: "",
    investmentAmountData2: "",
    investmentAmount3: "",
    investmentAmountData3: "",
    estimatedAmount: "",
    estimatedAmountData: "",
    estimatedAmountView: [],
    growthResources: "",
    growthResourcesData: "",
    growthResourcesView: [],
    supplierss: "",
    supplierssData: "",
    supplierssView: [],
    errorSupplierss: false,
    errorGrowthResources: false,
    errorEstimatedAmount: false,
    errorInvestmentAmount3: false,
    errorInvestmentAmount2: false,
    errorExportedCountry: false,
    errorInvestmentAmount: false,
    errorProjectEmployees2: false,
    errorProjectEmployees: false,
    errorAnnualBilling2: false,
    errorAnnualBilling: false,
    errorSuppliers: false,
    errorNeedPartners: false,
    errorNeedsValue: false,
    errorHasValue: false,
    errorBilling: false,
    errorEducationCenter: false,
    errorCatCommunityValue: false,
    errorTrainingProcesss: false,
    errorNumberPartners: false,
    errorSteps: false,
    errorCatSectoresValue: false,
    errorCatProjectTypeValue: false,
    errorGender: false,
    errorProvince: false,
    errorCity: false,
    errorStartactivities: false,
    errorDescriptionbusiness: false,
    errorBusinessName: false,
    errorGraduationYear: false,
    errorStudySite: false,
    errorCareer: false,
    errorEmail: false,
    errorDocument: false,
    errorLastNames: false,
    errorNames: false,
    yearFromMaxDate: new Date(),
    yearFromMinDate: Moment().subtract(50, "years"),
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const data = {
      catalogs: [
        {
          detailName: "catProjectType",
          codeExclusion: "0",
        },
        {
          detailName: "catSectores",
          codeExclusion: "0",
        },
        {
          detailName: "catCommunity",
          codeExclusion: "0",
        },
        {
          detailName: "catNeedsHas",
          codeExclusion: "0",
        },
      ],
    };

    const consultar = async () => {
      await axios
        .post(`${urlServices}catalogsDetails/getMultiplesCatalogs`, data, {
          headers: {
            application: keyAplication,
          },
        })
        .then(
          (response) => {
            setCatProjectType(response.data.catalogs.catProjectType);
            setCatSectores(response.data.catalogs.catSectores);
            setCatCommunity(response.data.catalogs.catCommunity);
            setHas(response.data.catalogs.catNeedsHas);
            setNeeds(response.data.catalogs.catNeedsHas);
            setIsLoaded(false);
          },
          (error) => {
            console.log(error);
            setMensaje_error("Error al consultar los catalogos");
            setOpenAlert(true);
          }
        );
    };
    consultar();
  }, [urlServices, keyAplication]);

  const handleNext = () => {
    if (activeStep === 0) {
      // STEP 1
      setoOpenBackdrop(true);

      /* if (values.marca === "") {
        setValues({ ...values, errorMarca: true });
        setMensaje_error("Seleccione una MARCA");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.modelo === "") {
        setValues({ ...values, errorModelo: true });
        setMensaje_error("Seleccione un MODELO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.subtipo === "") {
        setValues({ ...values, errorSubtipo: true });
        setMensaje_error("Seleccione un SUBTIPO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.subtipo === "") {
        setValues({ ...values, errorSubtipo: true });
        setMensaje_error("Seleccione un SUBTIPO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.province === "") {
        setValues({ ...values, errorProvince: true });
        setMensaje_error("Seleccione una PROVINCIA");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.ciudad === "") {
        setValues({ ...values, errorCiudad: true });
        setMensaje_error("Seleccione una CIUDAD");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.anio === null) {
        setValues({ ...values, errorAnio: true });
        setMensaje_error("Ingrese un AÑO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.kilometraje === "") {
        setValues({ ...values, errorKilometraje: true });
        setMensaje_error("Ingrese el KILOMETRAJE");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.color === "") {
        setValues({ ...values, errorColor: true });
        setMensaje_error("Seleccione un COLOR");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.puertas === "") {
        setValues({ ...values, errorPuertas: true });
        setMensaje_error("Ingrese el número de PUERTAS");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.ultimoNumPlaca === "") {
        setValues({ ...values, errorUltimoNumPlaca: true });
        setMensaje_error("Ingrese el último número de la PLACA");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else {*/
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setoOpenBackdrop(false);
      }, 500);
      // }
    } else if (activeStep === 1) {
      // STEP 2
      setoOpenBackdrop(true);

      /* if (values.carGlasses === "") {
        setValues({ ...values, errorCarGlasses: true });
        setMensaje_error("Seleccione un TIPO DE VIDRIO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.carTransmission === "") {
        setValues({ ...values, errorCarTransmission: true });
        setMensaje_error("Seleccione un TIPO DE TRANSMISIÓN");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.carFuel === "") {
        setValues({ ...values, errorCarFuel: true });
        setMensaje_error("Seleccione un TIPO DE COMBUSTIBLE");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.carTraction === "") {
        setValues({ ...values, errorCarTraction: true });
        setMensaje_error("Seleccione un TIPO DE TRACCIÓN");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else {*/
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setoOpenBackdrop(false);
      }, 500);
      // }
    } else if (activeStep === 2) {
      // STEP 3
      setoOpenBackdrop(true);

      /* if (file.length === 0) {
        setMensaje_error("No hay FOTOS seleccionadas");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else {*/
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setoOpenBackdrop(false);
      }, 500);
      // }
    } else if (activeStep === 3) {
      // STEP 4
      setoOpenBackdrop(true);

      /* if (values.precio === "") {
        setValues({ ...values, errorPrecio: true });
        setMensaje_error("Ingrese un PRECIO");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (values.descriptionCar === "") {
        setValues({ ...values, errorDescriptionCar: true });
        setMensaje_error("Ingrese una Breve DESCRIPCIÓN");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else if (!values.callwhatsapp) {
        setMensaje_error("Activar la opción de contacto");
        setOpenAlert(true);
        setoOpenBackdrop(false);
      } else {*/
      setTimeout(() => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        window.scrollTo({ top: 0, behavior: "smooth" });
        setoOpenBackdrop(false);
      }, 500);
      //  }
    } /*else if (activeStep === 4) {
      // STEP 5

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      window.scrollTo({ top: 0, behavior: "smooth" });
    }*/
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleClose = (event, reason) => {
    setOpenAlert(false);
    setOpenAlertsuccess(false);
  };

  const handleCloseSuccess = (event, reason) => {
    setOpenAlert(false);
    setOpenAlertsuccess(false);
  };

  const handleGraduationYear = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        graduationYear: event,
        errorGraduationYear: false,
      });
    }
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const changeProvince = (data) => {
    let response, disabled, detailName;
    if (data !== null) {
      response = data.id;
      detailName = data.name;
      disabled = false;
    } else {
      response = "";
      detailName = "";
      disabled = true;
    }
    setValues({
      ...values,
      province: response,
      provinceName: detailName,
      disabledCity: disabled,
      city: "",
      cityValue: null,
      provinceValue: data,
      errorProvince: false,
      latitude: -0.18093079060265435,
      longitude: -78.46778869628906,
    });
  };

  const changeCities = (data) => {
    let response, lat, lon, detailName;

    if (data !== null) {
      response = data.id;
      detailName = data.name;
      lat = data.lat;
      lon = data.lon;
    } else {
      response = "";
      detailName = "";
      lat = -0.18093079060265435;
      lon = -78.46778869628906;
    }

    setValues({
      ...values,
      city: response,
      cityName: detailName,
      cityValue: data,
      errorCity: false,
      latitude: lat,
      longitude: lon,
    });
  };

  const getResponseGender = (pro) => {
    setValues({
      ...values,
      gender: pro !== null ? pro.code : "",
      genderData: pro !== null ? pro : "",
      errorGender: false,
    });
  };

  const getResponseSteps = (pro) => {
    setValues({
      ...values,
      steps: pro !== null ? pro.code : "",
      stepsData: pro !== null ? pro : "",
      errorSteps: false,
    });
  };

  const getResponseTrainingProcess = (pro) => {
    setValues({
      ...values,
      trainingProcess: pro !== null ? pro.code : "",
      trainingProcessData: pro !== null ? pro : "",
      errorTrainingProcesss: false,
    });
  };

  const getResponseProjectEmployees = (pro) => {
    setValues({
      ...values,
      projectEmployees: pro !== null ? pro.code : "",
      projectEmployeesData: pro !== null ? pro : "",
      errorProjectEmployees: false,
    });
  };

  const getResponseBilling = (event) => {
    if (event.target.value === "0") {
      setBillingShow(true);
    } else {
      setBillingShow(false);
    }

    setValues({
      ...values,
      billing: event.target.value,
      numberEmployees: event.target.value === "1" ? "" : values.numberEmployees,
      billingYears: event.target.value === "1" ? "" : values.billingYears,
    });
  };

  const getResponseNeedPartners = (pro) => {
    setValues({
      ...values,
      needPartners: pro !== null ? pro.code : "",
      needPartnersData: pro !== null ? pro : "",
      needPartnersView:
        pro !== null ? [{ catalog_id: pro.catalog_id, needs: pro.code }] : [],
      errorNeedPartners: false,
    });
  };

  const getResponseEducationCenter = (pro) => {
    setValues({
      ...values,
      educationCenter: pro !== null ? pro.code : "",
      educationCenterData: pro !== null ? pro : "",
      errorEducationCenter: false,
    });
  };

  const getResponseEstimatedAmount = (pro) => {
    setValues({
      ...values,
      estimatedAmount: pro !== null ? pro.code : "",
      estimatedAmountData: pro !== null ? pro : "",
      estimatedAmountView:
        pro !== null ? [{ catalog_id: pro.catalog_id, needs: pro.code }] : [],
      errorEstimatedAmount: false,
    });
  };

  const getResponseGrowthResources = (pro) => {
    setValues({
      ...values,
      growthResources: pro !== null ? pro.code : "",
      growthResourcesData: pro !== null ? pro : "",
      growthResourcesView:
        pro !== null ? [{ catalog_id: pro.catalog_id, needs: pro.code }] : [],
      errorGrowthResources: false,
    });
  };

  const getResponseSuppliers = (pro) => {
    setValues({
      ...values,
      supplierss: pro !== null ? pro.code : "",
      supplierssData: pro !== null ? pro : "",
      supplierssView:
        pro !== null ? [{ catalog_id: pro.catalog_id, needs: pro.code }] : [],
      errorSupplierss: false,
    });
  };

  const getResponseAnnualBilling = (pro) => {
    setValues({
      ...values,
      annualBilling: pro !== null ? pro.code : "",
      annualBillingData: pro !== null ? pro : "",
      errorAnnualBilling: false,
    });
  };

  const getResponseAnnualBilling2 = (pro) => {
    setValues({
      ...values,
      annualBilling2: pro !== null ? pro.code : "",
      annualBillingData2: pro !== null ? pro : "",
      errorAnnualBilling2: false,
    });
  };

  const getResponseProjectEmployees2 = (pro) => {
    setValues({
      ...values,
      projectEmployees2: pro !== null ? pro.code : "",
      projectEmployeesData2: pro !== null ? pro : "",
      errorProjectEmployees2: false,
    });
  };

  const getResponseInvestmentAmount = (pro) => {
    setValues({
      ...values,
      investmentAmount: pro !== null ? pro.code : "",
      investmentAmountData: pro !== null ? pro : "",
      errorInvestmentAmount: false,
    });
  };

  const getResponseInvestmentAmount2 = (pro) => {
    setValues({
      ...values,
      investmentAmount2: pro !== null ? pro.code : "",
      investmentAmountData2: pro !== null ? pro : "",
      errorInvestmentAmount2: false,
    });
  };

  const getResponseInvestmentAmount3 = (pro) => {
    setValues({
      ...values,
      investmentAmount3: pro !== null ? pro.code : "",
      investmentAmountData3: pro !== null ? pro : "",
      errorInvestmentAmount3: false,
    });
  };

  const getResponselt = (latitude) => {
    setValues({ ...values, latitude: latitude });
  };

  const getResponselng = (longitude) => {
    setValues({ ...values, longitude: longitude });
  };

  const getResponseExportSalesPastYear = (event) => {
    setValues({ ...values, exportSalesPastYear: event.target.value });
  };

  const includeHasArrayHave = (views) => {
    let output = [];

    views.map((items, i) => {
      if (items.view.length > 0) {
        items.view.map((items2, i) => {
          output.push({
            have_id: items2.code,
            catalog_id: items2.cat_id,
            another: "0",
            required_hours: items2.required_hours,
            experience_years: items2.experience_years,
          });
        });
      }
    });
    return output;
  };

  const includeHasArrayNeed = (views) => {
    let output = [];

    views.map((items, i) => {
      if (items.view.length > 0) {
        items.view.map((items2, i) => {
          output.push({
            need_id: items2.code,
            catalog_id: items2.cat_id,
            another: "0",
            required_hours: items2.required_hours,
            partners: null,
            capital_amount_id: null,
            growth_resources_id: null,
            supplier_id: null,
          });
        });
      }
    });
    return output;
  };

  const submitForm = (e) => {
    e.preventDefault();
    setOpenAlertsuccess(false);
    setoOpenBackdrop(true);
    setOpenAlert(false);

    let arrayHave = [
      {
        view: values.hasValueView,
      },
    ];

    let have = includeHasArrayHave(arrayHave);

    let arrayNeed = [
      {
        view: values.needsValueView,
      },
    ];

    let need = includeHasArrayNeed(arrayNeed);

    if (values.needPartnersView.length > 0) {
      values.needPartnersView.map((items2, i) => {
        need.push({
          need_id: items2.needs,
          catalog_id: items2.catalog_id,
          another: null,
          required_hours: null,
          partners: null,
          capital_amount_id: null,
          growth_resources_id: null,
          supplier_id: null,
        });
      });
    }

    if (values.estimatedAmountView.length > 0) {
      values.estimatedAmountView.map((items2, i) => {
        need.push({
          need_id: items2.needs,
          catalog_id: items2.catalog_id,
          another: null,
          required_hours: null,
          partners: null,
          capital_amount_id: null,
          growth_resources_id: null,
          supplier_id: null,
        });
      });
    }

    if (values.growthResourcesView.length > 0) {
      values.growthResourcesView.map((items2, i) => {
        need.push({
          need_id: items2.needs,
          catalog_id: items2.catalog_id,
          another: null,
          required_hours: null,
          partners: null,
          capital_amount_id: null,
          growth_resources_id: null,
          supplier_id: null,
        });
      });
    }

    if (values.supplierssView.length > 0) {
      values.supplierssView.map((items2, i) => {
        need.push({
          need_id: items2.needs,
          catalog_id: items2.catalog_id,
          another: null,
          required_hours: null,
          partners: null,
          capital_amount_id: null,
          growth_resources_id: null,
          supplier_id: null,
        });
      });
    }

    const dataValues = {
      name: values.names,
      last_name: values.lastNames,
      document_id: values.document,
      email: values.email.toLowerCase(),
      degree: values.career,
      degree_year: format(values.graduationYear, "yyyy"),
      city_id: values.city,
      city_another: "0",
      lat: values.latitude,
      lon: values.longitude,
      gender_id: values.gender,
      gender_another: "0",
      project_name: values.businessName,
      description: values.descriptionbusiness,
      started_at: format(values.startactivities, "yyyy"),
      current_step_id: values.steps,
      partners: values.numberPartners,
      training_process_id: values.trainingProcess,
      training_process_another: "0",
      education_center_id: values.educationCenter,
      education_center_another: "0",
      billing: values.billing,
      year_bills: values.billingYears,
      employees: values.numberEmployees,
      profile_id: 1,
      have: have,
      need: need,
    };
    console.log(dataValues);
    axios
      .post(`${urlServices}stakeholders`, dataValues, {
        headers: {
          application: keyAplication,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          setOpenAlertsuccess(true);
          setMensaje_success(
            "Bienvenido(a), se ha enviado un correo electrónico."
          );
          setoOpenBackdrop(false);
        }
      })
      .catch((e) => {
        if (e.response.status === 402) {
          setMensaje_error("Error al registrar los datos");
          setOpenAlert(true);
          setoOpenBackdrop(false);
        } else {
          setMensaje_error("Error al registrar los datos");
          setOpenAlert(true);
          console.log(e);
          setoOpenBackdrop(false);
        }
      });
  };

  const handleReset = () => {
    setActiveStep(0);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleValiEmail = (prop) => {
    /*setErrorFieldEmail(false);

    const data = new FormData();
    data.append("email", prop);

    axios.post(urlServices + "routes.php?servicio=validarcorreo", data).then(
      (response) => {
        if (response.data.code === 0) {
          setErrorFieldEmail(true);
          setMensaje_validate("Correo electrónico registrado!");
          setDisabledButton(true);
        } else {
          setMensaje_validate("");
          setDisabledButton(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );*/
  };

  const handleChangeStartActivities = (date) => {
    setValues({ ...values, startactivities: date });
  };

  const changeCatProjectTypeValue = (e, data) => {
    setValues({ ...values, catProjectTypeValue: data });
  };

  const changeCatSectoresValue = (e, data) => {
    setValues({ ...values, catSectoresValue: data });
  };

  const changeCatCommunityValue = (e, data) => {
    setValues({ ...values, catCommunityValue: data });
  };

  const changeHasValue = (e, data) => {
    var datos = [],
      output = "";

    if (data.length >= values.hasValue.length) {
      for (var i = 0; i < data.length; i++) {
        if (values.hasValue.length > 0) {
          for (var i1 = 0; i1 < values.hasValue.length; i1++) {
            if (values.hasValue[i1] !== data[i]) {
              datos = [
                {
                  code: data[i].code,
                  id: data[i].id,
                  detailName: data[i].detailName,
                  cat_id: data[i].catalog_id,
                },
              ];
            }
          }
        } else {
          datos = [
            {
              code: data[i].code,
              id: data[i].id,
              detailName: data[i].detailName,
              cat_id: data[i].catalog_id,
            },
          ];
        }
      }
      setValues({
        ...values,
        hasValue: data,
        errorHasValue: false,
      });
      setCatSelectValue({ value: data, cat: "hasValue" });
      setCatSelect(true);
      setdataCat(datos);
    } else {
      console.log(data);
      output = deleteCatViews(data, values.hasValueView);

      setValues({
        ...values,
        hasValue: data,
        hasValueView: data.length !== 0 ? output : data,
      });
    }
  };

  const changeNeedsValue = (e, data) => {
    var datos = [],
      output = "";

    if (data.length >= values.needsValue.length) {
      for (var i = 0; i < data.length; i++) {
        if (values.needsValue.length > 0) {
          for (var i1 = 0; i1 < values.needsValue.length; i1++) {
            if (values.needsValue[i1] !== data[i]) {
              datos = [
                {
                  code: data[i].code,
                  id: data[i].id,
                  detailName: data[i].detailName,
                  cat_id: data[i].catalog_id,
                },
              ];
            }
          }
        } else {
          datos = [
            {
              code: data[i].code,
              id: data[i].id,
              detailName: data[i].detailName,
              cat_id: data[i].catalog_id,
            },
          ];
        }
      }
      setValues({
        ...values,
        needsValue: data,
        errorNeedsValue: false,
      });
      setCatSelectValueNeeds({ value: data, cat: "needsValue" });
      setCatSelectNeeds(true);
      setdataCatNeeds(datos);
    } else {
      output = deleteCatViews(data, values.needsValueView);

      setValues({
        ...values,
        needsValue: data,
        needsValueView: data.length !== 0 ? output : data,
      });
    }
  };

  const callbackResourcesData = (data) => {
    if (data.save !== 0) {
      let arr = values.hasValueView;
      arr.push({
        detailName: data.detailName,
        code: data.code,
        cat_id: data.cat_id,
        required_hours: data.requiredHours,
        experience_years: data.yearsExperience,
      });

      setValues({ ...values, hasValueView: arr });
    } else {
      catSelectValue.value.map((items, i) => {
        if (items.catalog_id === data.cat_id) {
          if (items.code === data.code) {
            catSelectValue.value.splice(i, 1);
          }
        }
      });

      setValues({ ...values, [catSelectValue.cat]: catSelectValue.value });
    }

    setCatSelect(false);
  };

  const callbackResourcesDataNeeds = (data) => {
    if (data.save !== 0) {
      let arr = values.needsValueView;
      arr.push({
        detailName: data.detailName,
        code: data.code,
        cat_id: data.cat_id,
        required_hours: data.requiredHours,
      });

      setValues({ ...values, needsValueView: arr });
    } else {
      catSelectValueNeeds.value.map((items, i) => {
        if (items.catalog_id === data.cat_id) {
          if (items.code === data.code) {
            catSelectValueNeeds.value.splice(i, 1);
          }
        }
      });

      setValues({
        ...values,
        [catSelectValueNeeds.cat]: catSelectValueNeeds.value,
      });
    }

    setCatSelectNeeds(false);
  };

  const deleteCatViews = (data, cat) => {
    for (var i = 0; i < cat.length; i++) {
      var isAGoodUser = false;
      for (var y = 0; y < data.length; y++) {
        if (cat[i].detailName === data[y].detailName) {
          isAGoodUser = true;
          break;
        }
      }
      if (!isAGoodUser) {
        cat.splice(i, 1);
      }
    }

    return cat;
  };

  const textCatSelect = (items) => {
    return (
      <Fragment>
        <p>
          <b>Horas requeridas:</b> {items.requiredHours}
          <br></br> <br></br>
          <b>Años de experiencia:</b> {items.yearsExperience}
        </p>
      </Fragment>
    );
  };

  const textCatSelectNeeds = (items) => {
    return (
      <Fragment>
        <p>
          <b>Horas requeridas:</b> {items.requiredHours}
        </p>
      </Fragment>
    );
  };

  return (
    <div className={classes.root}>
      <Hidden smDown implementation="css">
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<ColorlibConnector />}
          className={classes.containerStep}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Hidden>
      <div>
        {activeStep === steps.length ? (
          <div style={{ marginBottom: 100 }}>
            <Grid container spacing={3} className={classes.rootSteps}>
              <Grid item xs={12} sm={12} style={{ marginTop: 5 }}>
                <div className={classes.titleCardSub2}>
                  <Place className={classes.iconSubTitle} />
                  UBICACIÓN DEL EMPRENDIMIENTO
                </div>
              </Grid>

              <Grid item xs={12} md={12} className={classes.mapStyle}>
                <FormLabel component="legend">
                  <div className={classes.fontParagraph}>
                    <GpsFixed /> Habilita la ubicación de tu navegador.
                    <br></br>
                    Por favor, seleccione el puntero rojo y arrastre hasta la
                    ubicación.
                  </div>
                </FormLabel>

                <MapDraggableMarker
                  callbacklt={getResponselt}
                  callbacklng={getResponselng}
                  dataltlg={{ lt: values.latitude, lg: values.longitude }}
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 20 }}>
              <center>
                <Button
                  startIcon={<NavigateBefore />}
                  onClick={handleReset}
                  className={classes.buttonLeftSteps}
                >
                  Volver
                </Button>

                <Button onClick={submitForm} className={classes.buttonSubmit}>
                  REGISTRAR
                </Button>
              </center>
            </div>
          </div>
        ) : (
          <div style={{ marginBottom: 100 }}>
            <Typography>
              {getStepContent(
                activeStep,
                values,
                handleChange,
                classes,
                handleGraduationYear,
                handleValiEmail,
                handleChangeStartActivities,
                changeProvince,
                changeCities,
                getResponseGender,
                catProjectType,
                loader,
                changeCatProjectTypeValue,
                catSectores,
                changeCatSectoresValue,
                getResponseSteps,
                catCommunity,
                changeCatCommunityValue,
                getResponseTrainingProcess,
                getResponseEducationCenter,
                getResponseBilling,
                billingShow,
                has,
                changeHasValue,
                needs,
                changeNeedsValue,
                textCatSelect,
                textCatSelectNeeds,
                getResponseNeedPartners,
                getResponseEstimatedAmount,
                getResponseGrowthResources,
                getResponseSuppliers,
                getResponseAnnualBilling,
                getResponseAnnualBilling2,
                getResponseProjectEmployees,
                getResponseProjectEmployees2,
                getResponseInvestmentAmount,
                getResponseExportSalesPastYear,
                getResponseInvestmentAmount2,
                getResponseInvestmentAmount3
              )}
            </Typography>
            <div>
              <center>
                <div>
                  {activeStep !== 0 ? (
                    <Button
                      onClick={handleBack}
                      className={classes.buttonLeftSteps}
                      startIcon={<NavigateBefore />}
                    >
                      Regresar
                    </Button>
                  ) : (
                    ""
                  )}
                  <Button
                    className={classes.buttonNextSteps}
                    endIcon={<NavigateNext />}
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === steps.length - 1
                      ? "Finalizar"
                      : "Siguiente"}
                  </Button>
                </div>
              </center>
            </div>
          </div>
        )}
      </div>

      <Backdrop
        style={{ zIndex: "9999", color: "#FFFFFF" }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={openAlert}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        onClose={handleClose}
      >
        <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
          <Alert
            onClose={handleClose}
            severity="error"
            elevation={6}
            variant="filled"
          >
            {mensaje_error}
          </Alert>
        </Slide>
      </Snackbar>
      {openAlertsuccess ? (
        <SuccessfulMessage
          callbackFalse={handleCloseSuccess}
          callbackTrue={openAlertsuccess}
          mensaje_success={mensaje_success}
        />
      ) : (
        ""
      )}
      {catSelect ? (
        <ResourcesData
          callback={callbackResourcesData}
          open={catSelect}
          data={dataCat}
        />
      ) : (
        ""
      )}
      {catSelectNeeds ? (
        <ResourcesDataNeeds
          callback={callbackResourcesDataNeeds}
          open={catSelectNeeds}
          data={dataCatNeeds}
        />
      ) : (
        ""
      )}
    </div>
  );
}

function getSteps() {
  return ["Datos personales", "Emprendimiento", "Conocimientos", "Encuesta"];
}

function getStepContent(
  stepIndex,
  values,
  handleChange,
  classes,
  handleGraduationYear,
  handleValiEmail,
  handleChangeStartActivities,
  changeProvince,
  changeCities,
  getResponseGender,
  catProjectType,
  loader,
  changeCatProjectTypeValue,
  catSectores,
  changeCatSectoresValue,
  getResponseSteps,
  catCommunity,
  changeCatCommunityValue,
  getResponseTrainingProcess,
  getResponseEducationCenter,
  getResponseBilling,
  billingShow,
  has,
  changeHasValue,
  needs,
  changeNeedsValue,
  textCatSelect,
  textCatSelectNeeds,
  getResponseNeedPartners,
  getResponseEstimatedAmount,
  getResponseGrowthResources,
  getResponseSuppliers,
  getResponseAnnualBilling,
  getResponseAnnualBilling2,
  getResponseProjectEmployees,
  getResponseProjectEmployees2,
  getResponseInvestmentAmount,
  getResponseExportSalesPastYear,
  getResponseInvestmentAmount2,
  getResponseInvestmentAmount3
) {
  switch (stepIndex) {
    case 0:
      return step1Form(
        values,
        handleChange,
        classes,
        handleGraduationYear,
        handleValiEmail,
        changeProvince,
        changeCities,
        getResponseGender
      );
    case 1:
      return step2Form(
        values,
        classes,
        handleChange,
        handleChangeStartActivities,
        catProjectType,
        loader,
        changeCatProjectTypeValue,
        catSectores,
        changeCatSectoresValue,
        getResponseSteps,
        catCommunity,
        changeCatCommunityValue,
        getResponseTrainingProcess,
        getResponseEducationCenter,
        getResponseBilling,
        billingShow
      );
    case 2:
      return step3Form(
        values,
        classes,
        has,
        changeHasValue,
        loader,
        needs,
        changeNeedsValue,
        textCatSelect,
        textCatSelectNeeds,
        getResponseNeedPartners,
        getResponseEstimatedAmount,
        getResponseGrowthResources,
        getResponseSuppliers
      );
    case 3:
      return step4Form(
        values,
        classes,
        getResponseAnnualBilling,
        getResponseAnnualBilling2,
        getResponseProjectEmployees,
        getResponseProjectEmployees2,
        getResponseInvestmentAmount,
        getResponseExportSalesPastYear,
        handleChange,
        getResponseInvestmentAmount2,
        getResponseInvestmentAmount3
      );

    default:
      return "Unknown stepIndex";
  }
}

function step1Form(
  values,
  handleChange,
  classes,
  handleGraduationYear,
  handleValiEmail,
  changeProvince,
  changeCities,
  getResponseGender
) {
  return (
    <Fragment>
      <Grid container spacing={3} className={classes.rootSteps}>
        <Grid item xs={12} sm={12}>
          <div className={classes.titleCardFilter}>Datos personales</div>
          <div className={classes.lineBottom}></div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="names"
            value={values.names}
            error={values.errorNames}
            onChange={handleChange("names")}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            label="Nombres"
            margin="dense"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="lastNames"
            value={values.lastNames}
            error={values.errorLastNames}
            onChange={handleChange("lastNames")}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            label="Apellidos"
            margin="dense"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="document"
            value={values.document}
            error={values.errorDocument}
            onChange={handleChange("document")}
            fullWidth
            required
            inputProps={{ maxLength: 12 }}
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            label="Cédula"
            margin="dense"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="email"
            value={values.email}
            error={values.errorEmail}
            onChange={handleChange("email")}
            onInput={(e) => {
              handleValiEmail(e.target.value);
            }}
            fullWidth
            required
            inputProps={{ maxLength: 50 }}
            label="Correo electrónico"
            margin="dense"
            // helperText={mensaje_validate}
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <LoadProvince
            refresh={changeProvince}
            value={values.provinceValue}
            error={values.errorProvince}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <LoadCities
            disabled={values.disabledCity}
            idProvince={values.province}
            refresh={changeCities}
            value={values.cityValue}
            error={values.errorCity}
          />
        </Grid>

        {/*<Grid item xs={12} sm={6}>
          <TextField
            name="studySite"
            value={values.studySite}
            error={values.errorStudySite}
            onChange={handleChange("studySite")}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            label="Centro de estudio"
            margin="dense"
            autoComplete="off"
          />
        </Grid>*/}

        <Grid item xs={12} sm={6}>
          <TextField
            name="career"
            value={values.career}
            error={values.errorCareer}
            onChange={handleChange("career")}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            label="Carrera cursada"
            margin="dense"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                label="Año de graduación"
                onChange={handleGraduationYear}
                value={values.graduationYear}
                format="yyyy"
                views={["year"]}
                animateYearScrolling={true}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                inputVariant="outlined"
                keyboardIcon={<Today className={classes.iconTheme} />}
                maxDate={values.yearFromMaxDate}
                autoComplete="off"
                InputProps={{ readOnly: true }}
                autoOk
                minDateMessage={false}
                maxDateMessage={false}
                error={values.errorGraduationYear}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} sm={6}>
          <LoadGender
            callback={getResponseGender}
            value={values.genderData}
            error={values.errorGender}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

function step2Form(
  values,
  classes,
  handleChange,
  handleChangeStartActivities,
  catProjectType,
  loader,
  changeCatProjectTypeValue,
  catSectores,
  changeCatSectoresValue,
  getResponseSteps,
  catCommunity,
  changeCatCommunityValue,
  getResponseTrainingProcess,
  getResponseEducationCenter,
  getResponseBilling,
  billingShow
) {
  return (
    <Fragment>
      <Grid container spacing={3} className={classes.rootSteps}>
        <Grid item xs={12} sm={12}>
          <div className={classes.titleCardFilter}>Emprendimiento</div>
          <div className={classes.lineBottom}></div>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            name="businessName"
            value={values.businessName}
            error={values.errorBusinessName}
            onChange={handleChange("businessName")}
            fullWidth
            required
            inputProps={{ maxLength: 100 }}
            label="Nombre"
            margin="dense"
            autoComplete="off"
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ThemeProvider theme={defaultMaterialTheme}>
              <KeyboardDatePicker
                id="date-picker-dialog"
                label="Inicio de actividades"
                onChange={handleChangeStartActivities}
                value={values.startactivities}
                format="dd/MM/yyyy"
                openTo="year"
                animateYearScrolling={true}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                inputVariant="outlined"
                keyboardIcon={<Today className={classes.iconTheme} />}
                maxDate={values.yearFromMaxDate}
                autoComplete="off"
                InputProps={{ readOnly: true }}
                autoOk
                minDateMessage={false}
                maxDateMessage={false}
                error={values.errorGraduationYear}
              />
            </ThemeProvider>
          </MuiPickersUtilsProvider>
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextField
            name="descriptionbusiness"
            value={values.descriptionbusiness}
            error={values.errorDescriptionbusiness}
            onChange={handleChange("descriptionbusiness")}
            fullWidth
            required
            inputProps={{ maxLength: 150 }}
            label="Descripción"
            margin="dense"
            rows="2"
            multiline
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loader ? (
            <Fragment>
              <FormLabel component="legend">Tipo de emprendimiento</FormLabel>
              <center>
                <Skeleton width={300} height={40} />
              </center>
            </Fragment>
          ) : (
            <Fragment>
              <Autocomplete
                multiple
                size="small"
                id="catProjectTypeValue"
                options={catProjectType}
                value={values.catProjectTypeValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option.detailName}
                onChange={changeCatProjectTypeValue}
                getOptionSelected={(option, value) => {
                  return option.detailName === value.detailName;
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.detailName}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.errorCatProjectTypeValue}
                    name="catProjectTypeValue"
                    required
                    label="Tipo de emprendimiento"
                    margin="dense"
                  />
                )}
              />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          {loader ? (
            <Fragment>
              <FormLabel component="legend">Sector al que pertence</FormLabel>
              <center>
                <Skeleton width={300} height={40} />
              </center>
            </Fragment>
          ) : (
            <Fragment>
              <Autocomplete
                multiple
                size="small"
                id="catSectoresValue"
                options={catSectores}
                value={values.catSectoresValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option.detailName}
                onChange={changeCatSectoresValue}
                getOptionSelected={(option, value) => {
                  return option.detailName === value.detailName;
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.detailName}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.errorCatSectoresValue}
                    name="catSectoresValue"
                    required
                    label="Sector al que pertenece"
                    margin="dense"
                  />
                )}
              />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadSteps
            callback={getResponseSteps}
            value={values.stepsData}
            error={values.errorSteps}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="numberPartners"
            value={values.numberPartners}
            error={values.errorNumberPartners}
            onChange={handleChange("numberPartners")}
            fullWidth
            required
            onInput={(e) => {
              e.target.value = e.target.value.replace(/[^0-9]/g, "");
            }}
            label="Número de socios"
            margin="dense"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadTrainingProcess
            callback={getResponseTrainingProcess}
            value={values.trainingProcessData}
            error={values.errorTrainingProcesss}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loader ? (
            <Fragment>
              <FormLabel component="legend">Sector al que pertence</FormLabel>
              <center>
                <Skeleton width={300} height={40} />
              </center>
            </Fragment>
          ) : (
            <Fragment>
              <Autocomplete
                multiple
                size="small"
                id="catCommunityValue"
                options={catCommunity}
                value={values.catCommunityValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option.detailName}
                onChange={changeCatCommunityValue}
                getOptionSelected={(option, value) => {
                  return option.detailName === value.detailName;
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.detailName}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.errorCatCommunityValue}
                    name="catCommunityValue"
                    required
                    label="Comunidad - Asociación"
                    margin="dense"
                  />
                )}
              />
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadEducationCenter
            callback={getResponseEducationCenter}
            value={values.educationCenterData}
            error={values.errorEducationCenter}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="age-native-simple">
              ¿Estás facturando?
            </InputLabel>
            <Select
              native
              value={values.billing}
              onChange={getResponseBilling}
              inputProps={{
                name: "billing",
                id: "age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={0}>SI</option>
              <option value={1}>NO</option>
            </Select>
          </FormControl>
        </Grid>
        {billingShow ? (
          <Fragment>
            <Grid item xs={12} sm={6}>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={1000}
                isVisible={true}
              >
                <TextField
                  name="billingYears"
                  value={values.billingYears}
                  onChange={handleChange("billingYears")}
                  fullWidth
                  required
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  label="¿Cuánto facturas por año?"
                  margin="dense"
                  autoComplete="off"
                />
              </Animated>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Animated
                animationIn="fadeIn"
                animationOut="fadeOut"
                animationInDuration={1000}
                isVisible={true}
              >
                <TextField
                  name="numberEmployees"
                  value={values.numberEmployees}
                  onChange={handleChange("numberEmployees")}
                  fullWidth
                  required
                  onInput={(e) => {
                    e.target.value = e.target.value.replace(/[^0-9]/g, "");
                  }}
                  label="Número de empleados"
                  margin="dense"
                  autoComplete="off"
                />
              </Animated>
            </Grid>
          </Fragment>
        ) : (
          ""
        )}
      </Grid>
    </Fragment>
  );
}

function step3Form(
  values,
  classes,
  has,
  changeHasValue,
  loader,
  needs,
  changeNeedsValue,
  textCatSelect,
  textCatSelectNeeds,
  getResponseNeedPartners,
  getResponseEstimatedAmount,
  getResponseGrowthResources,
  getResponseSuppliers
) {
  return (
    <Fragment>
      <Grid container spacing={3} className={classes.rootSteps}>
        <Grid item xs={12} sm={12}>
          <div className={classes.titleCardFilter}>Banco de conocimientos</div>
          <div className={classes.lineBottom}></div>
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: 5 }}>
          <div className={classes.titleCardSub2}>
            <Reorder className={classes.iconSubTitle} />
            YO TENGO
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          {loader ? (
            <Fragment>
              <FormLabel component="legend">Conocimientos que TENGO</FormLabel>
              <center>
                <Skeleton width={300} height={40} />
              </center>
            </Fragment>
          ) : (
            <Fragment>
              <Autocomplete
                multiple
                size="small"
                id="hasValue"
                options={has}
                value={values.hasValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option.detailName}
                onChange={changeHasValue}
                getOptionSelected={(option, value) => {
                  return option.detailName === value.detailName;
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.detailName}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.errorHasValue}
                    name="hasValue"
                    //  required
                    label="Conocimientos que TENGO"
                    margin="dense"
                  />
                )}
              />
              <List>
                {values.hasValueView.map((items, i) => {
                  return (
                    <ListItem
                      key={items}
                      role={undefined}
                      dense
                      className={classes.listCatalogSelect}
                    >
                      <ListItemIcon>
                        <HtmlTooltip title={textCatSelect(items)}>
                          <Pageview style={{ cursor: "pointer" }} />
                        </HtmlTooltip>
                      </ListItemIcon>
                      <ListItemText
                        id={items}
                        primary={`${items.detailName}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={12} style={{ marginTop: 15 }}>
          <div className={classes.titleCardSub2}>
            <Reorder className={classes.iconSubTitle} />
            YO NECESITO
          </div>
        </Grid>
        <Grid item xs={12} sm={12}>
          {loader ? (
            <Fragment>
              <FormLabel component="legend">
                Conocimientos que NECESITO
              </FormLabel>
              <center>
                <Skeleton width={300} height={40} />
              </center>
            </Fragment>
          ) : (
            <Fragment>
              <Autocomplete
                multiple
                size="small"
                id="needsValue"
                options={needs}
                value={values.needsValue}
                disableCloseOnSelect
                getOptionLabel={(option) => option.detailName}
                onChange={changeNeedsValue}
                getOptionSelected={(option, value) => {
                  return option.detailName === value.detailName;
                }}
                renderOption={(option, { selected }) => (
                  <Fragment>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                      color="primary"
                    />
                    {option.detailName}
                  </Fragment>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    error={values.errorNeedsValue}
                    name="hasValue"
                    //  required
                    label="Conocimientos que NECESITO"
                    margin="dense"
                  />
                )}
              />
              <List>
                {values.needsValueView.map((items, i) => {
                  return (
                    <ListItem
                      key={items}
                      role={undefined}
                      dense
                      className={classes.listCatalogSelect}
                    >
                      <ListItemIcon>
                        <HtmlTooltip title={textCatSelectNeeds(items)}>
                          <Pageview style={{ cursor: "pointer" }} />
                        </HtmlTooltip>
                      </ListItemIcon>
                      <ListItemText
                        id={items}
                        primary={`${items.detailName}`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </Fragment>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          <div className={classes.titleCardFilter}>Recursos para crecer</div>
          <div className={classes.lineBottom}></div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadCatPartners
            callback={getResponseNeedPartners}
            value={values.needPartnersData}
            error={values.errorNeedPartners}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadEstimatedAmount
            callback={getResponseEstimatedAmount}
            value={values.estimatedAmountData}
            error={values.errorEstimatedAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadGrowthResources
            callback={getResponseGrowthResources}
            value={values.growthResourcesData}
            error={values.errorGrowthResources}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadSuppliers
            callback={getResponseSuppliers}
            value={values.supplierssData}
            error={values.errorSuppliers}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}

function step4Form(
  values,
  classes,
  getResponseAnnualBilling,
  getResponseAnnualBilling2,
  getResponseProjectEmployees,
  getResponseProjectEmployees2,
  getResponseInvestmentAmount,
  getResponseExportSalesPastYear,
  handleChange,
  getResponseInvestmentAmount2,
  getResponseInvestmentAmount3
) {
  return (
    <Fragment>
      <Grid container spacing={2} className={classes.rootSteps}>
        <Grid item xs={12} sm={12}>
          <div className={classes.titleCardFilter}>Encuesta de rendimiento</div>
          <div className={classes.lineBottom}></div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadAnnualBilling
            callback={getResponseAnnualBilling}
            value={values.annualBillingData}
            error={values.errorAnnualBilling}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadAnnualBilling2
            callback={getResponseAnnualBilling2}
            value={values.annualBillingData2}
            error={values.errorAnnualBilling2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadProjectEmployees
            callback={getResponseProjectEmployees}
            value={values.projectEmployeesData}
            error={values.errorProjectEmployees}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadProjectEmployees2
            callback={getResponseProjectEmployees2}
            value={values.projectEmployeesData2}
            error={values.errorProjectEmployees2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadInvestmentAmount
            callback={getResponseInvestmentAmount}
            value={values.investmentAmountData}
            error={values.errorInvestmentAmount}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl style={{ width: "100%" }}>
            <InputLabel htmlFor="age-native-simple">
              ¿Has exportado tus productos el año pasado?
            </InputLabel>
            <Select
              native
              value={values.exportSalesPastYear}
              onChange={getResponseExportSalesPastYear}
              inputProps={{
                name: "exportSalesPastYear",
                id: "age-native-simple",
              }}
            >
              <option aria-label="None" value="" />
              <option value={0}>SI</option>
              <option value={1}>NO</option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            name="exportedCountry"
            value={values.exportedCountry}
            error={values.errorExportedCountry}
            onChange={handleChange("exportedCountry")}
            fullWidth
            required
            label="¿Que pais ha exportado?"
            margin="dense"
            autoComplete="off"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadInvestmentAmount2
            callback={getResponseInvestmentAmount2}
            value={values.investmentAmountData2}
            error={values.errorInvestmentAmount2}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <LoadInvestmentAmount3
            callback={getResponseInvestmentAmount3}
            value={values.investmentAmountData3}
            error={values.errorInvestmentAmount3}
          />
        </Grid>
      </Grid>
    </Fragment>
  );
}
