import React, {
  Fragment,
  useContext,
  forwardRef,
  useState,
  useEffect,
} from "react";

import { UrlServicesContext } from "components/UrlServicesContext";

import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import format from "date-fns/format";
import { Cancel, CheckCircle } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import axios from "axios";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewCompositionsAdmin(props) {
  const classes = useStyles();

  const [rowsConfectionItem, setRowsConfectionItem] = useState([]);

  let keyAuthorization = localStorage.getItem("Session");
  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  let data = props.data;

  useEffect(() => {
    axios
      .get(`${urlServices}compositions-details/composition/${props.data.id}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          setRowsConfectionItem(response.data);
        },
        (error) => {
          setMensaje("Error de conexión, intente más tarde.");
          setOpenAlert(true);
          setError("error");
        }
      );
  }, []);

  const handleCloseDialog = () => {
    props.exit();
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Ver insumos</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer style={{ marginTop: 5 }}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      <StyledTableRow>
                        <TableCell component="th" scope="row" colSpan={2}>
                          <b style={{ float: "right" }}>
                            {format(
                              new Date(data.created_at),
                              "dd-MM-yyyy kk:mm:ss"
                            )}
                          </b>
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell component="th" scope="row">
                          <b>Producto:</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.product_name}
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell component="th" scope="row">
                          <b>Modelo:</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.model_name}
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell component="th" scope="row">
                          <b>Talla:</b>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {data.size_name}
                        </TableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <br></br>
                <center>
                  {" "}
                  <div className={classes.cardSubTitle}>
                    <b>INSUMOS</b>
                  </div>
                </center>
              </Grid>
              <Grid item xs={12} md={12}>
                {rowsConfectionItem.length > 0 ? (
                  <Fragment>
                    <TableContainer style={{ marginTop: 5 }}>
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableBody>
                          {rowsConfectionItem.map((value) => (
                            <Fragment>
                              <StyledTableRow>
                                <TableCell component="th" scope="row">
                                  <CheckCircle style={{ fontSize: 16 }} />
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {value.confection_item_name}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  <b style={{ fontSize: 10 }}>Cantidad: </b>{" "}
                                  {value.quantity}
                                </TableCell>
                              </StyledTableRow>
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Fragment>
                ) : (
                  <center>
                    <b style={{ color: "#C5C5C5" }}>
                      <br></br>No hay insumos.<br></br>
                    </b>
                  </center>
                )}
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
