import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import {
  Cancel,
  PermMedia,
  CheckBoxOutlineBlank,
  CheckBox,
} from "@material-ui/icons";
import Resizer from "react-image-file-resizer";
import FileBase64 from "react-file-base64";
import Avatar from "@material-ui/core/Avatar";
import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Checkbox from "@material-ui/core/Checkbox";


import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      "100%",
      "100%",
      "JPEG",
      18,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export default function NewProductCompleteAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    code: "",
    name: "",
    detail: "",
    model: [],
    modelData: [],
    productsTypes: "",
    productsTypesData: "",
    Imgbase64: "",
    errorName: false,
    errorDetail: false,
    errorCode: false,
    errorModel: false,
    errorProductsType: false,
  });

  useEffect(() => {
    axios
      .get(`${urlServices}models`, {
        headers: {
          application: keyAplication,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setValues({
              ...values,
              model: response.data,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }, [urlServices, keyAplication]);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorCode: false,
      errorDetail: false,
    });
    setOpenAlert(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setopenAlertSuccess(false);

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje("Agregar un nombre.");
      setOpenAlert(true);
      setError("error");
    } else if (values.detail.length === 0) {
      setValues({ ...values, errorDetail: true });
      setMensaje("Agregar un detalle.");
      setOpenAlert(true);
      setError("error");
    } else if (values.code.length === 0) {
      setValues({ ...values, errorCode: true });
      setMensaje("Agregar un código.");
      setOpenAlert(true);
      setError("error");
    } else if (values.productsTypes === "") {
      setValues({ ...values, errorProductsType: true });
      setMensaje("Seleccione un tipo producto.");
      setOpenAlert(true);
      setError("error");
    } else if (values.modelData.length === 0) {
      setValues({ ...values, errorModel: true });
      setMensaje("Seleccione un modelo.");
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue = {
        code: values.code,
      };

      axios
        .post(`${urlServices}products/validateCode`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            setValues({ ...values, errorCode: true });
            setMensaje("El código ingresado ya existe.");
            setOpenAlert(true);
            setError("error");
          }
        })
        .catch((e) => {
          if (e.response.status === 404) {
            sendRegister();
          }
        });
    }
  };

  const sendRegister = () => {
    const dataValue = {
      code: values.code,
      name: values.name,
      detail: values.detail,
      picture: values.Imgbase64.length === 0 ? "" : values.Imgbase64,
      product_type_id: values.productsTypes,
      models_id: 0,
    };

    axios
      .post(`${urlServices}products`, dataValue, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (values.modelData.length > 0) {
            values.modelData.map((items, i) => {
              const dataValueModels = {
                product_id: response.data.id,
                model_id: items.id,
              };

              axios
                .post(`${urlServices}products-models`, dataValueModels, {
                  headers: {
                    application: keyAplication,
                    Authorization: "Bearer " + keyAuthorization,
                  },
                })
                .then(
                  (response) => {},
                  (error) => {}
                );
            });
          }

          setopenAlertSuccess(true);
          setmensaje_success("Registro guardado con éxito.");
          props.callBackRefresh();

          setValues({
            code: "",
            name: "",
            detail: "",
            model: [],
            modelData: [],
            productsTypes: "",
            productsTypesData: "",
            Imgbase64: "",
          });
        },
        (error) => {
          setMensaje("Error de conexión, intente más tarde.");
          setOpenAlert(true);
          setError("error");
        }
      );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeModel = (e, data) => {
    setValues({
      ...values,
      modelData: data !== null ? data : [],
      errorModel: false,
    });

    setOpenAlert(false);
  };

  const changeProdcutsTypes = (data) => {
    setValues({
      ...values,
      productsTypes: data !== null ? data.code : "",
      productsTypesData: data !== null ? data : "",
      errorProductsType: false,
    });

    setOpenAlert(false);
  };

  const uploadSingleFile = async (e) => {
    let image = "",
      outfile = "";

    if (e.length > 0 && e.length <= 1) {
      if (Number(e[0].file.size) > 500000) {
        // SI ES MAYOR A 500KB LE BAJAMOS EL PESO
        image = await resizeFile(e[0].file);
        // const newFile = dataURIToBlob(image);
        outfile = image;
      } else {
        outfile = e[0].base64;
      }
      setValues({ ...values, Imgbase64: outfile });
    }
  };

  const icon = <CheckBoxOutlineBlank fontSize="small" />;
  const checkedIcon = <CheckBox fontSize="small" />;

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container className={classes.containerUnit}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>
                      Nuevo producto
                    </div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
                  <Grid item xs={12} sm={12} className={classes.containerImg}>
                    <center>
                      <Avatar
                        alt="Avatar"
                        src={values.Imgbase64 === "" ? "" : values.Imgbase64}
                        className={classes.imgUserProfile}
                      >
                        <PermMedia />
                      </Avatar>
                      <br></br>
                      <label className={classes.buttonSubmit2}>
                        <FileBase64 multiple={true} onDone={uploadSingleFile} />
                        <i class="fa fa-cloud-upload"></i> Seleccionar foto
                      </label>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombre"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="detail"
                        value={values.detail}
                        error={values.errorDetail}
                        onChange={handleChange("detail")}
                        fullWidth
                        required
                        label="Detalle"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="code"
                        value={values.code}
                        error={values.errorCode}
                        onChange={handleChange("code")}
                        fullWidth
                        required
                        label="Código"
                        margin="dense"
                        autoComplete="off"
                        helperText="Ingresar el código que desee (números o letras)."
                      />
                    </FormControl>
                  </Grid>                
                  <Grid item xs={12} md={12}>
                    <Autocomplete
                      multiple
                      size="small"
                      id="model"
                      options={values.model.map((option) => ({
                        id: option.id,
                        name: option.name + " (" + option.model_type_name + ")",
                      }))}
                      value={values.modelData}
                      disableCloseOnSelect
                      getOptionLabel={(option) => option.name}
                      onChange={changeModel}
                      getOptionSelected={(option, value) => {
                        return option.name === value.name;
                      }}
                      renderOption={(option, { selected }) => (
                        <Fragment>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                            color="primary"
                          />
                          {option.name}
                        </Fragment>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={values.errorModel}
                          name="model"
                          required
                          label="Modelos"
                          margin="dense"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      {openAlertSuccess ? (
                        <Alert severity="success">{mensaje_success}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
