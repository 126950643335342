import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Cancel, Layers, AddCircle } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import axios from "axios";
import MaterialTable from "material-table";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Button from "@material-ui/core/Button";

import DeleteCatAdmin from "views/dialog/DeleteCatAdmin";
import NewModelTypesListAdmin from "views/dialog/NewModelTypesListAdmin";
import EditCatalogDetails from "views/dialog/EditCatalogDetails";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ModelTypesListAdmin() {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);
  const [idRow, setIdRow] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openBackdrop, setoOpenBackdrop] = useState(false);
  const [openNewModelTypes, setOpenNewModelTypes] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Nombre",
        field: "detailName",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Código",
        field: "code",
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "CENTER",
        },
      },
      {
        title: "Catalogo",
        field: "catalogName",
        width: "25%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
      },
      {
        title: "Estatus",
        field: "status",
        render: (rowData) => {
          const text = rowData.status === 1 ? "ACTIVO" : "INACTIVO";
          const color = rowData.status === 1 ? "green" : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const cat = {
      catalogs: [
        {
          detailName: "catModelTypes",
          codeExclusion: "0",
        },
      ],
    };
    axios
      .post(`${urlServices}catalogsDetails/getMultiplesCatalogs`, cat, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRows(response.data.catalogs.catModelTypes);
            setLoader(false);
          } else {
            setRows([]);
            setLoader(false);
          }
        },
        (error) => {
          console.log(error);
          setRows([]);
          setError(true);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization]);

  const RefreshTable = () => {
    setoOpenBackdrop(true);

    const cat = {
      catalogs: [
        {
          detailName: "catModelTypes",
          codeExclusion: "0",
        },
      ],
    };

    axios
      .post(`${urlServices}catalogsDetails/getMultiplesCatalogs`, cat, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRows(response.data.catalogs.catModelTypes);
            setoOpenBackdrop(false);
          } else {
            setRows([]);
            setoOpenBackdrop(false);
          }
        },
        (error) => {
          setoOpenBackdrop(false);

          console.log(error);
          setRows([]);
          setError(true);
        }
      );
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseNewModelTypes = () => {
    setOpenNewModelTypes(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Layers className={classes.iconWhite} /> Listado de tipos de
              modelos registrados
            </div>
          </CardHeader>
          <CardBody>
            {error ? (
              <Alert severity="error">
                Error en la consulta con sel servidor.
              </Alert>
            ) : (
              ""
            )}
            {loader ? (
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      width="40%"
                      height={40}
                      style={{ float: "right" }}
                    />
                    <Skeleton width="100%" height={300} />
                  </Box>
                </Grid>
              </Grid>
            ) : (
              <Fragment>
                <Grid container>
                  <Grid item xs={12} sm={12}>
                    <Button
                      className={classes.buttonRight}
                      onClick={(event) => {
                        setOpenNewModelTypes(true);
                      }}
                      startIcon={<AddCircle />}
                    >
                      Agregar tipo modelo
                    </Button>
                  </Grid>
                </Grid>
                <MaterialTable
                  title=""
                  columns={state.columns}
                  style={{
                    boxShadow: "unset",
                    border: "1px solid rgb(241, 241, 241)",
                  }}
                  data={rows}
                  localization={{
                    pagination: {
                      labelRowsSelect: "Filas",
                      labelDisplayedRows: " {from}-{to} de {count}",
                      firstTooltip: "Primera página",
                      previousTooltip: "Previo",
                      nextTooltip: "Siguiente",
                      lastTooltip: "Ultima página",
                    },
                    toolbar: {
                      // nRowsSelected: '{0} row(s) selected',
                      searchTooltip: "Filtrar",
                      searchPlaceholder: "Buscar",
                    },
                    header: {
                      actions: " --- ",
                    },
                    body: {
                      emptyDataSourceMessage: "No hay datos.",
                      filterRow: {
                        filterTooltip: "Filtro",
                      },
                    },
                  }}
                  actions={[
                    /* {
                      icon: "search",
                      tooltip: "Ver",
                      onClick: (event, rowData) => {
                        setopenViewUser(true);
                        setIdRow(rowData);
                      },
                    },*/
                    {
                        icon: "edit",
                        tooltip: "Editar",
                        onClick: (event, rowData) => {
                          setOpenEdit(true);
                          setIdRow(rowData);
                        },
                      },
                    /*{
                        icon: "delete",
                        tooltip: "Eliminar",
                        onClick: (event, rowData) => {
                          setOpenDelete(true);
                          setIdRow(rowData);
                        },
                      },*/
                    (rowData1) => ({
                      icon: () => <Cancel />,
                      tooltip: "Deshabilitar",
                      onClick: (event, rowData) => {
                        setOpenDelete(true);
                        setIdRow(rowData);
                      },
                      hidden: rowData1.status === 1 ? false : true,
                    }),
                    {
                      icon: "refresh",
                      tooltip: "Refresh Data",
                      isFreeAction: true,
                      onClick: () => RefreshTable(),
                    },
                  ]}
                  icons={tableIcons}
                  options={{
                    search: true,
                    padding: "default",
                    filtering: false,
                    actionsColumnIndex: -1,
                    pageSize: 10,
                    headerStyle: {
                      background: "#F2F2F2",
                      padding: "1%",
                      fontWeight: "bold",
                      textAlign: "center",
                    },
                    cellStyle: { border: "1px solid #DDDDDD" },
                  }}
                />
              </Fragment>
            )}
          </CardBody>
        </Card>
      </GridItem>
      {openDelete ? (
        <DeleteCatAdmin
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openNewModelTypes ? (
        <NewModelTypesListAdmin
          open={openNewModelTypes}
          exit={handleCloseNewModelTypes}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openEdit ? (
        <EditCatalogDetails
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      <Backdrop
        style={{ zIndex: "9999", color: "#FFFFFF" }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </GridContainer>
  );
}
