import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import TableContainer from "@material-ui/core/TableContainer";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import Moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Cancel } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import axios from "axios";
import Alert from "@material-ui/lab/Alert";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ViewReception(props) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const [loader, setLoader] = useState(true);
  const [error, setError] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    axios
      .get(`${urlServices}receptions/${props.id.id}/summary`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          if (response.status === 200) {
            setRows(response.data);
            setLoader(false);
          } else {
            setRows([]);
            setLoader(false);
          }
        },
        (error) => {
          console.log(error);
          setRows([]);
          setError(true);
        }
      );
  }, [keyAplication, urlServices, keyAuthorization]);

  const handleCloseDialog = () => () => {
    props.exit();
  };

  let Totalok = 0,
    TotalPrice = 0,
    Subtotal = 0,
    Totalquantity = 0;
  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleCloseDialog()}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        scroll="body"
        keepMounted
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} md={9}>
              <div className={classes.titleCardFilter}>Registro de recepción</div>
              <div className={classes.lineBottom}></div>
            </Grid>
            {error ? (
              <Grid item xs={12} sm={12}>
                <Alert severity="error">
                  Error en la consulta con sel servidor.
                </Alert>
              </Grid>
            ) : (
              ""
            )}
            {loader ? (
              <Grid item xs={12} sm={12}>
                <Box sx={{ width: "100%" }}>
                  <Skeleton
                    width="40%"
                    height={40}
                    style={{ float: "right" }}
                  />
                  <Skeleton width="100%" height={300} />
                </Box>
              </Grid>
            ) : (
              <Fragment>
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <b>Asignación Nº:</b> {rows.reception.assignment_id}
                            <span style={{ float: "right" }}>
                              <b>Registro:</b>{" "}
                              {Moment(rows.reception.created_at).format(
                                "YYYY-MM-DD kk:mm:ss"
                              )}
                            </span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Cliente:</b> {rows.reception.dressmaker_name}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            <b>Observación general:</b>{" "}
                            {rows.reception.comments}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                          <TableCell
                            colSpan={7}
                            style={{
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            DETALLE
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.titleFilterAction}>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Producto | Modelo
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Talla
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Recibido
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Entrega
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Ent. Mal
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Pendiente
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Observación
                          </TableCell>
                        </TableRow>
                        {rows.receptionDetail.map((row) => {
                          Totalquantity += parseFloat(row.receive);
                          return (
                            <TableRow key={row.id}>
                              <TableCell
                                style={{
                                  backgroundColor: "rgb(250, 235, 215)",
                                  width: 200,
                                }}
                              >
                                {row.product_name} |{" "}
                                <b style={{ fontSize: 12 }}>
                                  {row.model_name} ({row.model_type_name})
                                </b>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  backgroundColor: "rgb(250, 235, 215)",
                                  width: 20,
                                }}
                              >
                                {row.size_name}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.receive}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.delivered_ok}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.delivered_bad}
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                {parseFloat(row.receive) -
                                  parseFloat(row.delivered_ok) -
                                  parseFloat(row.delivered_bad)}
                              </TableCell>
                              <TableCell>{row.comment}</TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{
                              backgroundColor: "#F9F9F9",
                              fontSize: 12,
                              fontWeight: "bold",
                              textAlign: "right",
                            }}
                          >
                            Cant. total
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            {Totalquantity}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={12}>
                  <br></br>
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableRow style={{ backgroundColor: "#F2F2F2" }}>
                          <TableCell
                            colSpan={5}
                            style={{
                              fontWeight: "600",
                              textAlign: "center",
                            }}
                          >
                            ENTREGA | PRECIO
                          </TableCell>
                        </TableRow>
                        <TableRow className={classes.titleFilterAction}>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Producto | Modelo
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Talla
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Entrega
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Precio unit.
                          </TableCell>
                          <TableCell
                            style={{
                              fontWeight: "400",
                              textAlign: "center",
                            }}
                          >
                            Precio total
                          </TableCell>
                        </TableRow>
                        {rows.receptionDetail.map((row) => {
                          Totalok += parseFloat(row.delivered_ok);
                          TotalPrice =
                            parseFloat(row.delivered_ok) * row.unit_price;
                          Subtotal +=
                            parseFloat(row.delivered_ok) * row.unit_price;
                          return (
                            <TableRow key={row.id}>
                              <TableCell
                                style={{
                                  backgroundColor: "rgb(250, 235, 215)",
                                  width: 200,
                                }}
                              >
                                {row.product_name} |{" "}
                                <b style={{ fontSize: 12 }}>
                                  {row.model_name} ({row.model_type_name})
                                </b>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                  backgroundColor: "rgb(250, 235, 215)",
                                  width: 20,
                                }}
                              >
                                {row.size_name}
                              </TableCell>
                              <TableCell style={{ textAlign: "center" }}>
                                {row.delivered_ok}
                              </TableCell>
                              <TableCell>
                                <center> ${row.unit_price.toFixed(2)}</center>
                              </TableCell>
                              <TableCell
                                style={{
                                  textAlign: "center",
                                  fontWeight: "bold",
                                }}
                              >
                                <center> ${TotalPrice.toFixed(2)}</center>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                        <TableRow>
                          <TableCell
                            colSpan={2}
                            style={{
                              backgroundColor: "#F9F9F9",
                              fontSize: 12,
                              fontWeight: "bold",
                              textAlign: "right",
                            }}
                          >
                            Cant. total
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            {Totalok}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 12,
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            Subtotal
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: 16,
                              fontWeight: "bold",
                              textAlign: "center",
                              backgroundColor: "#F9F9F9",
                            }}
                          >
                            ${Subtotal.toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog()}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
