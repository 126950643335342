import React, { Fragment } from "react";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";

import LoginView from "../views/Login";
import Header from "../components/Navbars/HeaderLogin";
import Footer from "../components/Footer/FooterAccount";

export default function Login() {
  return (
    <Fragment>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Header />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}></GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <LoginView />
        </GridItem>
        <GridItem xs={12} sm={12} md={3}></GridItem>
        <Footer />
      </GridContainer>
    </Fragment>
  );
}
