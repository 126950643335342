import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import NumberFormat from "react-number-format";
import { Cancel } from "@material-ui/icons";

// SELECT
import LoadProfile from "components/services/LoadProfile";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewPlanAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [errorServer, setErrorServer] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    name: "",
    lastName: "",
    document_id: "",
    profile: "",
    address: "",
    phone: "",
    profileData: "",
    email: "",
    errorName: false,
    errorLastName: false,
    errorProfile: false,
    errorDocument_id: false,
    errorEmail: false,
    errorAddress: false,
    errorPhone: false,
  });

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorDocument_id: false,
      errorEmail: false,
      errorAddress: false,
      errorPhone: false,
    });
    setOpenAlert(false);
  };

  const changeProfile = (data) => {
    setValues({
      ...values,
      profile: data !== null ? data.code : "",
      profileData: data !== null ? data : "",
      errorProfile: false,
    });

    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");

    if (values.name.length === 0) {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.lastName.length === 0) {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellido" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.document_id.length === 0) {
      setValues({ ...values, errorDocument_id: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.email.length === 0) {
      setValues({ ...values, errorEmail: true });
      setMensaje('El campo "Email" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.profile === "") {
      setValues({ ...values, errorProfile: true });
      setMensaje("Seleccione un perfil.");
      setOpenAlert(true);
      setError("error");
    } else if (values.phone === "") {
      setValues({ ...values, errorPhone: true });
      setMensaje('El campo "Teléfono" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else if (values.address === "") {
      setValues({ ...values, errorAddress: true });
      setMensaje('El campo "Dirección" es obligatorio.');
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue = {
        email: values.email.toLowerCase().trim(),
      };

      axios
        .post(urlServices + "users/validateEmail", dataValue, {
          headers: {
            application: keyAplication,
          },
        })
        .then(
          (response) => {
            if (!response.data.resCode) {
              const dataValue2 = {
                document_id: values.document_id.toString(),
                name: values.name,
                last_name: values.lastName,
                email: values.email,
                admin: 0,
                profile_id: values.profile.toString(),
                address: values.address,
                phone: values.phone.toString(),
                status: 2,
              };

              axios
                .post(`${urlServices}users`, dataValue2, {
                  headers: {
                    application: keyAplication,
                    Authorization: "Bearer " + keyAuthorization,
                  },
                })
                .then(
                  (response2) => {
                    setopenAlertSuccess(true);
                    setmensaje_success("Registro guardado con éxito.");
                    props.callBackRefresh();

                    setValues({
                      name: "",
                      lastName: "",
                      document_id: "",
                      profile: "",
                      profileData: "",
                      address: "",
                      phone: "",
                      email: "",
                    });
                  },
                  (error) => {
                    console.log(error);
                    setErrorServer(true);
                  }
                );
            } else {
              setMensaje("Este correo ya está registrado");
              setOpenAlert(true);
              setError("error");

              setValues({ ...values, errorEmail: true });
            }
          },
          (error) => {
            setMensaje("Error de conexión, intente más tarde.");
            setOpenAlert(true);
            setError("error");
          }
        );
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                {errorServer ? (
                  <Alert severity="error">
                    Error en la consulta al servidor
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Nuevo usuario</div>
                    <div className={classes.lineBottom}></div>
                    <div className={classes.titleCardFilter}>
                      {" "}
                      <small>
                        Al registrar este usuario, se le enviará a su correo una
                        clave temporal para el inicio de sesión. (Revisar tanto
                        la bandeja de entrada como la del spam).
                      </small>
                    </div>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="name"
                        value={values.name}
                        error={values.errorName}
                        onChange={handleChange("name")}
                        fullWidth
                        required
                        label="Nombre"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="lastName"
                        value={values.lastName}
                        error={values.errorLastName}
                        onChange={handleChange("lastName")}
                        fullWidth
                        required
                        label="Apellido"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="document_id"
                      value={values.document_id}
                      onChange={handleChange("document_id")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                          marginTop: 25,
                        },
                      }}
                      label="Documento"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      helperText="Cédula, RUC o pasaporte."
                      error={values.errorDocument_id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      autoComplete="off"
                      name="email"
                      value={values.email}
                      onChange={handleChange("email")}
                      label="E-mail"
                      error={values.errorEmail}
                      className={classes.textField2}
                      fullWidth
                    />
                  </Grid>

                  <Grid item xs={6} md={6}>
                    <FormControl style={{ width: "100%", marginTop: 5 }}>
                      <LoadProfile
                        value={values.profileData}
                        refresh={changeProfile}
                        error={values.errorProfile}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <NumberFormat
                      customInput={TextField}
                      name="phone"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      //prefix={"$"}
                      // thousandSeparator="."
                      //  decimalSeparator=","
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                        },
                      }}
                      label="Teléfono"
                      fullWidth
                      autoComplete="off"
                      margin="dense"
                      error={values.errorPhone}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="address"
                        value={values.address}
                        error={values.errorAddress}
                        onChange={handleChange("address")}
                        fullWidth
                        required
                        label="Dirección"
                        margin="dense"
                        autoComplete="off"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <center>
                      {openAlertSuccess ? (
                        <Alert severity="success">{mensaje_success}</Alert>
                      ) : (
                        ""
                      )}
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
