import React, { useState, forwardRef, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Cancel, Assignment, AddCircle } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import format from "date-fns/format";

import DeleteConfectionCatAdmin from "views/dialog/DeleteConfectionCatAdmin";
import NewConfectionAdmin from "views/dialog/NewConfectionAdmin";
import EditConfectionAdmin from "views/dialog/EditConfectionAdmin";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function ConfectionListAdmin() {
  const classes = useStyles();
  const tableRef = React.createRef();

  const [idRow, setIdRow] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openNewConfection, setOpenNewConfection] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Descripción",
        field: "name",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
        sorting: false,
      },
      {
        title: "Unidad",
        field: "unit_name",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
        sorting: false,
      },
      {
        title: "Código",
        field: "code",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
        },
        sorting: false,
      },
      {
        title: "Registro",
        render: (rowData) =>
          format(new Date(rowData.created_at), "dd-MM-yyyy kk:mm:ss"),
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 14,
          textAlign: "center",
        },
        sorting: false,
      },
      {
        title: "Estatus",
        field: "status",
        render: (rowData) => {
          const text = rowData.status === 1 ? "ACTIVO" : "INACTIVO";
          const color = rowData.status === 1 ? "green" : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
        sorting: false,
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseNewConfection = () => {
    setOpenNewConfection(false);
  };

  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  let url = "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <Assignment className={classes.iconWhite} /> Listado de Insumos
            </div>
          </CardHeader>
          <CardBody>
            <Grid container>
              <Grid item xs={12} sm={12}>
                <Button
                  className={classes.buttonRight}
                  onClick={(event) => {
                    setOpenNewConfection(true);
                  }}
                  startIcon={<AddCircle />}
                >
                  Agregar insumo
                </Button>
              </Grid>
            </Grid>
            <MaterialTable
              title=""
              tableRef={tableRef}
              columns={state.columns}
              style={{
                boxShadow: "unset",
                border: "1px solid rgb(241, 241, 241)",
              }}
              data={(query) =>
                new Promise((resolve, reject) => {
                  if (query.search === "") {
                    url = `${urlServices}confectionItems?limit=${
                      query.pageSize
                    }&page=${query.page + 1}`;
                  } else {
                    url = `${urlServices}confectionItems?limit=0&page=${
                      query.page + 1
                    }`;
                  }
                  fetch(url, {
                    method: "get",
                    headers: {
                      application: keyAplication,
                      Authorization: "Bearer " + keyAuthorization,
                    },
                  })
                    .then((response) => response.json())
                    .then((result) => {
                      resolve({
                        //data: result.data,
                        data: result.data.filter(function (obj) {
                          return Object.keys(obj).some(function (key) {
                            return obj[key]
                              ? obj[key]
                                  .toString()
                                  .toLowerCase()
                                  .includes(query.search)
                              : false;
                          });
                        }),
                        page: result.page - 1,
                        totalCount: result.total,
                      });
                    });
                })
              }
              localization={{
                pagination: {
                  labelRowsSelect: "Filas",
                  labelDisplayedRows: " {from}-{to} de {count}",
                  firstTooltip: "Primera página",
                  previousTooltip: "Previo",
                  nextTooltip: "Siguiente",
                  lastTooltip: "Ultima página",
                },
                toolbar: {
                  // nRowsSelected: '{0} row(s) selected',
                  searchTooltip: "Filtrar",
                  searchPlaceholder: "Buscar",
                },
                header: {
                  actions: " --- ",
                },
                body: {
                  emptyDataSourceMessage: "No hay datos.",
                  filterRow: {
                    filterTooltip: "Filtro",
                  },
                },
              }}
              actions={[
                /* {
                        icon: "search",
                        tooltip: "Ver",
                        onClick: (event, rowData) => {
                          setopenViewUser(true);
                          setIdRow(rowData);
                        },
                      },*/
                {
                  icon: "edit",
                  tooltip: "Editar",
                  onClick: (event, rowData) => {
                    setOpenEdit(true);
                    setIdRow(rowData);
                  },
                },
                /*{
                          icon: "delete",
                          tooltip: "Eliminar",
                          onClick: (event, rowData) => {
                            setOpenDelete(true);
                            setIdRow(rowData);
                          },
                        },*/
                (rowData1) => ({
                  icon: () => <Cancel />,
                  tooltip: "Deshabilitar",
                  onClick: (event, rowData) => {
                    setOpenDelete(true);
                    setIdRow(rowData);
                  },
                  hidden: rowData1.status === 1 ? false : true,
                }),
                {
                  icon: "refresh",
                  tooltip: "Refresh Data",
                  isFreeAction: true,
                  onClick: () =>
                    tableRef.current && tableRef.current.onQueryChange(),
                },
              ]}
              icons={tableIcons}
              options={{
                search: true,
                padding: "default",
                filtering: false,
                actionsColumnIndex: -1,
                pageSize: 10,
                headerStyle: {
                  background: "#F2F2F2",
                  padding: "1%",
                  fontWeight: "bold",
                  textAlign: "center",
                },
                cellStyle: { border: "1px solid #DDDDDD" },
              }}
            />
          </CardBody>
        </Card>
      </GridItem>
      {openDelete ? (
        <DeleteConfectionCatAdmin
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openNewConfection ? (
        <NewConfectionAdmin
          open={openNewConfection}
          exit={handleCloseNewConfection}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openEdit ? (
        <EditConfectionAdmin
          open={openEdit}
          exit={handleCloseEdit}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
    </GridContainer>
  );
}
