import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import axios from "axios";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons/";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import Box from "@material-ui/core/Box";
import { Animated } from "react-animated-css";

import successImg from "assets/img/success.gif";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function EditPasswordRecovery(props) {
  const classes = useStyles();

  const [saveSuccess, setSaveSuccess] = useState(false);

  let idUser = Number(localStorage.getItem("id"));
  let keyAuthorization = localStorage.getItem("Session");

  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  const { urlServices, keyAplication, urlLocalMediaProfile } =
    useContext(UrlServicesContext);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = (e) => {
    e.preventDefault();

    const data = {
      status: 1,
    };

    if (values.password.length !== 0) {
      data.password = values.password;
    }

    axios
      .put(`${urlServices}/users/${idUser}`, data, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          localStorage.setItem("status", 1);
          setSaveSuccess(true);
          setTimeout(() => {
            props.exit();
          }, 3000);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const disabledButton = !values.password.length;

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Grid container spacing={1} className={classes.containerProfile}>
            <Grid item xs={12} sm={12}>
              <div className={classes.titleCardFilter}>
                Ingrese su nueva contraseña
              </div>
              <div className={classes.lineBottom}></div>
              <br></br>
            </Grid>
            {saveSuccess ? (
              <Grid item xs={12} sm={12}>
                <Animated
                  animationIn="bounceIn"
                  animationInDuration={1500}
                  isVisible={true}
                >
                  <center>
                    <img
                      src={successImg}
                      alt="successPassword"
                      style={{
                        width: "150px",
                      }}
                    />
                    <br></br>
                    <div className={classes.titleCard}>
                      Contraseña modificada con exito.
                    </div>
                  </center>
                </Animated>
              </Grid>
            ) : (
              <Fragment>
                <Grid item xs={12} sm={12}>
                  <center>
                    <Box width={300}>
                      <FormControl style={{ width: "100%", marginTop: 5 }}>
                        <InputLabel htmlFor="standard-adornment-password">
                          Nueva Contraseña
                        </InputLabel>
                        <Input
                          type={values.showPassword ? "text" : "password"}
                          value={values.password}
                          onChange={handleChange("password")}
                          required
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {values.showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                          margin="dense"
                        />
                      </FormControl>
                    </Box>
                  </center>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    paddingTop: 30,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    type="submit"
                    className={classes.buttonSubmit}
                    onClick={submitForm}
                    disabled={disabledButton}
                  >
                    Guardar
                  </Button>
                </Grid>
              </Fragment>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
}
