import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import {
  makeStyles,
  withStyles,
  ThemeProvider,
  createTheme,
} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import NumberFormat from "react-number-format";
import { Cancel, Delete, AddCircle } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Today } from "@material-ui/icons";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FormControl from "@material-ui/core/FormControl";
import Moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";


import {
  cardBodyStyle,
  themeCss,
} from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);
const defaultMaterialTheme = createTheme(themeCss);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditWeeklyAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [openAlertSuccess, setopenAlertSuccess] = useState(false);
  const [mensaje_success, setmensaje_success] = useState("");
  const [errorServer, setErrorServer] = useState(false);
  const [disabledTo, setdisabledTo] = useState(false);
  const [rowsProduct, setrowsProduct] = useState([]);
  const [rowsProductDelete, setrowsProductDelete] = useState([]);
  const [rowsProductNew, setrowsProductNew] = useState([]);

  const [loader, setLoader] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    yearFrom: props.id.starts_at,
    yearTo: props.id.ends_at,
    yearToMinDate: new Date(props.id.starts_at),
    description: props.id.description,
    product: "",
    productData: "",
    productName: "",
    quantity: "",
    errorYearFrom: false,
    errorYearTo: false,
    errorProduct: false,
    errorQuantity: false,
    errorDescription: false,
  });

  useEffect(() => {
    const consultar = () => {
      axios
        .get(`${urlServices}weekly-orders/${props.id.id}`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              let rowsValues = [];

              if (response.data.weeklyOrderDetail.length > 0) {
                response.data.weeklyOrderDetail.map((items, i) => {
                  rowsValues.push({
                    id: items.id,
                    product_id: items.product_id,
                    productName: items.product_name,
                    quantity: items.quantity,
                  });
                });
              }
              setrowsProduct(rowsValues);
              setLoader(false);
            } else {
              setLoader(false);
            }
          },
          (error) => {
            console.log(error);
            setError(true);
          }
        );
    };
    consultar();
  }, [props, keyAplication, urlServices, keyAuthorization]);

  const handleChange = (prop) => (event) => {
    setopenAlertSuccess(false);

    setValues({
      ...values,
      [prop]: event.target.value,
      errorQuantity: false,
      errorDescription: false,
    });
    setOpenAlert(false);
  };

  const handleDateChangeFrom = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearFrom: event,
        yearToMinDate: event,
        yearTo: null,
        errorYearFrom: false,
      });

      setdisabledTo(false);
    }
  };

  const handleDateChangeTo = (event) => {
    if (event !== null) {
      setValues({
        ...values,
        yearTo: event,
        errorYearTo: false,
      });
    }
  };

  const changeProduct = (data) => {
    setValues({
      ...values,
      product: data !== null ? data.id : "",
      productData: data !== null ? data : [],
      productName: data !== null ? data.name : "",
      errorProduct: false,
    });

    setOpenAlert(false);
  };

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setErrorServer(false);
    setopenAlertSuccess(false);

    if (values.yearFrom === null) {
      setValues({ ...values, errorYearFrom: true });
      setMensaje("Seleccione un día de INICIO.");
      setOpenAlert(true);
      setError("error");
    } else if (values.yearTo === null) {
      setValues({ ...values, errorYearTo: true });
      setMensaje("Seleccione un día FINAL.");
      setOpenAlert(true);
      setError("error");
    } else if (values.description.length === 0) {
      setValues({ ...values, errorDescription: true });
      setMensaje("Descripción corta que identifique al pedido.");
      setOpenAlert(true);
      setError("error");
    } else if (rowsProduct.length === 0) {
      setMensaje("Agregue al menos un producto.");
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValues = {
        starts_at: Moment(values.yearFrom).format("YYYY-MM-DD"),
        ends_at: Moment(values.yearTo).format("YYYY-MM-DD"),
        description: values.description,
      };

      axios
        .put(`${urlServices}weekly-orders/${props.id.id}`, dataValues, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              setopenAlertSuccess(true);
              setmensaje_success("Registro guardado con éxito.");
              props.callBackRefresh();

              if (rowsProductDelete.length > 0) {
                rowsProductDelete.map((items) => {
                  deleteProduct(items.id);
                });
              }

              if (rowsProductNew.length > 0) {
                rowsProductNew.map((items) => {
                  createProduct(items, props.id.id);
                });
              }

              rowsProduct.map((items) => {
                updateProduct(items);
              });

              setrowsProductDelete([]);
              setrowsProductNew([]);
            }
          },
          (error) => {
            console.log(error);
            setErrorServer(true);
          }
        );
    }
  };

  const deleteProduct = (items) => {
    axios
      .delete(`${urlServices}weekly-orders-details/${items}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
  };

  const updateProduct = (items) => {
    const dataUpdate = {
      quantity: items.quantity,
    };

    axios
      .put(`${urlServices}weekly-orders-details/${items.id}`, dataUpdate, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
  };

  const createProduct = (items, id) => {
    const dataCreate = {
      quantity: items.quantity,
      product_id: items.product_id,
      weekly_order_id: id,
    };

    axios
      .post(`${urlServices}weekly-orders-details`, dataCreate, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {},
        (error) => {
          console.log(error);
        }
      );
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const handleAddProduct = () => {
    setValues({ ...values, errorProduct: false, errorQuantity: false });

    if (values.product === "") {
      setValues({ ...values, errorProduct: true });
      setMensaje("Seleccione un producto.");
      setOpenAlert(true);
      setError("error");
    } else if (values.quantity.length === 0) {
      setValues({ ...values, errorQuantity: true });
      setMensaje("Agregue una cantidad al producto.");
      setOpenAlert(true);
      setError("error");
    } else {
      let includeID = rowsProduct.filter(
        (vendor) => vendor.product_id === values.product
      );

      if (includeID.length === 0) {
        let rows = [...rowsProduct],
          rows2 = [...rowsProductNew];

        rows.push({
          product_id: values.product,
          productName: values.productName,
          quantity: values.quantity,
        });

        rows2.push({
          product_id: values.product,
          productName: values.productName,
          quantity: values.quantity,
        });

        setrowsProduct(rows);
        setrowsProductNew(rows2);

        setValues({
          ...values,
          product: "",
          productData: [],
          productName: "",
          quantity: "",
          errorProduct: false,
          errorQuantity: false,
        });
      } else {
        setValues({
          ...values,
          errorProduct: true,
          errorModel: true,
          errorQuantity: true,
        });
        setMensaje("Ya hay un producto con el mismo nombre.");
        setOpenAlert(true);
        setError("error");
      }
    }
  };

  const handleRemoveClickProduct = (i, data) => {
    const list = [...rowsProduct];
    list.splice(i, 1);
    setrowsProduct(list);

    rowsProductDelete.push({ id: data.id });
    setrowsProductDelete(rowsProductDelete);
  };

  const handleInputChangeProduct = (e, index) => {
    const { name, value } = e.target;
    const list = [...rowsProduct];
    list[index][name] = value;
    setrowsProduct(list);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>Editar pedido</div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Día de inicio"
                          onChange={handleDateChangeFrom}
                          value={values.yearFrom}
                          error={values.errorYearFrom}
                          format="yyyy-MM-dd"
                          // views={["year"]}
                          animateYearScrolling={true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          inputVariant="outlined"
                          keyboardIcon={<Today className={classes.iconTheme} />}
                          autoComplete="off"
                          InputProps={{ readOnly: true }}
                          autoOk
                          minDateMessage={false}
                          maxDateMessage={false}
                          margin="dense"
                          required
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={6} md={6}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={defaultMaterialTheme}>
                        <KeyboardDatePicker
                          id="date-picker-dialog"
                          label="Día final"
                          onChange={handleDateChangeTo}
                          value={values.yearTo}
                          error={values.errorYearTo}
                          format="yyyy-MM-dd"
                          // views={["year"]}
                          animateYearScrolling={true}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          inputVariant="outlined"
                          keyboardIcon={
                            <Today
                              className={
                                disabledTo
                                  ? classes.iconWhite
                                  : classes.iconTheme
                              }
                            />
                          }
                          minDate={values.yearToMinDate}
                          autoComplete="off"
                          InputProps={{ readOnly: true }}
                          autoOk
                          minDateMessage={false}
                          maxDateMessage={false}
                          disabled={disabledTo}
                          margin="dense"
                          required
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <FormControl style={{ width: "100%" }}>
                      <TextField
                        name="description"
                        value={values.description}
                        onChange={handleChange("description")}
                        fullWidth
                        label="Descripción corta que identifique al pedido"
                        margin="dense"
                        autoComplete="off"
                        required
                      />
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <br></br>
                    <center>
                      {" "}
                      <div className={classes.cardSubTitle}>
                        <b>SELECCIONAR PRODUCTOS</b>
                      </div>
                    </center>
                  </Grid>

                
                  <Grid item xs={2} sm={3}>
                    <NumberFormat
                      customInput={TextField}
                      name="quantity"
                      value={values.quantity}
                      error={values.errorQuantity}
                      onChange={handleChange("quantity")}
                      allowNegative={false}
                      inputProps={{
                        style: {
                          textAlign: "center",
                          fontSize: 16,
                        },
                      }}
                      label="Cantidad"
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      margin="dense"
                      placeholder="0"
                    />
                  </Grid>
                  <Grid item xs={2} sm={3}>
                    <Button
                      className={classes.buttonLeft}
                      onClick={handleAddProduct}
                      startIcon={<AddCircle />}
                    >
                      Agregar
                    </Button>
                  </Grid>
                  <br></br>
                  <Grid item xs={12} sm={12}>
                    <span className={classes.cardTitleDialog}>
                      <small>Listado:</small>
                    </span>
                    {loader ? (
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <Box sx={{ width: "100%" }}>
                            <Skeleton width="100%" height={100} />
                          </Box>
                        </Grid>
                      </Grid>
                    ) : (
                      <Fragment>
                        {rowsProduct.length > 0 ? (
                          <Table
                            className={classes.table}
                            size="small"
                            aria-label="a dense table"
                          >
                            <TableBody>
                              <TableCell component="th" scope="row">
                                <b>Producto</b>{" "}
                              </TableCell>
                              <TableCell component="th" scope="row">
                                <center>
                                  <b>Cantidad</b>{" "}
                                </center>
                              </TableCell>
                              {rowsProduct.map((row1, i1) => {
                                return (
                                  <StyledTableRow>
                                    <TableCell
                                      // align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      {row1.productName}
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <TextField
                                        name="quantity"
                                        value={row1.quantity}
                                        onChange={(e) =>
                                          handleInputChangeProduct(e, i1)
                                        }
                                        onInput={(e) => {
                                          e.target.value =
                                            e.target.value.replace(
                                              /[^0-9]/g,
                                              ""
                                            );
                                        }}
                                        autoComplete="off"
                                        label="Cantidad"
                                        variant="outlined"
                                        margin="dense"
                                      />
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      component="th"
                                      scope="row"
                                    >
                                      <Delete
                                        style={{
                                          cursor: "pointer",
                                          float: "right",
                                        }}
                                        onClick={() =>
                                          handleRemoveClickProduct(i1, row1)
                                        }
                                      />
                                    </TableCell>
                                  </StyledTableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        ) : (
                          <center>
                            <b style={{ color: "#C5C5C5" }}>
                              <br></br>No hay prodcutos.<br></br>
                            </b>
                          </center>
                        )}
                      </Fragment>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <br></br>

                    <Grid item xs={12} sm={12}>
                      <center>
                        {openAlertSuccess ? (
                          <Alert severity="success">{mensaje_success}</Alert>
                        ) : (
                          ""
                        )}
                      </center>
                    </Grid>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    {errorServer ? (
                      <Alert severity="error">
                        Error en la consulta al servidor
                      </Alert>
                    ) : (
                      ""
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
