import React, { Fragment, useState, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { Cancel, AddCircle, Delete } from "@material-ui/icons";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import NumberFormat from "react-number-format";


import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function NewCompositionsAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [rowsConfectionItem, setRowsConfectionItem] = useState([]);
  const [disabledProductModels, setDisabledProductModels] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    quantity: "",
    confection: "",
    confectionName: "",
    confectionData: "",
    products: "",
    productsData: "",
    productsModel: "",
    productsModelData: "",
    sizes: [],
    sizesData: [],
    errorQuantity: false,
    errorConfection: false,
    errorProducts: false,
    errorProductsModels: false,
    errorSizes: false,
  });

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorQuantity: false,
    });
    setOpenAlert(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setOpenAlert(false);

    if (values.products.length === 0) {
      setValues({ ...values, errorProducts: true });
      setMensaje("Seleccionar un producto.");
      setOpenAlert(true);
      setError("error");
    } else if (values.productsModel === "") {
      setValues({ ...values, errorProductsModels: true });
      setMensaje("Seleccionar un modelo.");
      setOpenAlert(true);
      setError("error");
    } else if (values.sizes.length === 0) {
      setValues({ ...values, errorSizes: true });
      setMensaje("Seleccionar una talla con modelo.");
      setOpenAlert(true);
      setError("error");
    } else if (rowsConfectionItem.length === 0) {
      setValues({ ...values, errorConfection: true });
      setMensaje("No hay ningún insumo agregado.");
      setOpenAlert(true);
      setError("error");
    } else {
      let rows = values.sizes.length,
        n = 0;

      values.sizes.map((items) => {
        const dataValue = {
          product_id: values.products,
          model_id: values.productsModel,
          size_id: items.code,
        };

        axios
          .post(`${urlServices}compositions`, dataValue, {
            headers: {
              application: keyAplication,
              Authorization: "Bearer " + keyAuthorization,
            },
          })
          .then(
            (response) => {
              (async function () {
                await CompositionsDetails(response.data);
              })();
            },
            (error) => {
              setMensaje("Error de conexión, intente más tarde.");
              setOpenAlert(true);
              setError("error");
            }
          );
        n++;
      });

      if (rows === n) {
        console.log(n);
        setError("success");
        setMensaje("Registro guardado con éxito.");
        setOpenAlert(true);

        setRowsConfectionItem([]);
        setDisabledProductModels(true);

        props.callBackRefresh();

        setValues({
          quantity: "",
          confection: "",
          confectionName: "",
          confectionData: "",
          products: "",
          productsData: "",
          productsModel: "",
          productsModelData: "",
          sizes: [],
          sizesData: [],
        });
      }
    }
  };

  const CompositionsDetails = (data) => {
    rowsConfectionItem.map((items2) => {
      const dataValue = {
        quantity: parseFloat(items2.quantity.replace(/,/g, ".")),
        confection_item_id: items2.confection_item_id,
        composition_id: data.id,
      };

      axios
        .post(`${urlServices}compositions-details`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error);
          }
        );
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeConfection = (data) => {
    setValues({
      ...values,
      confection: data !== null ? data.id : "",
      confectionName: data !== null ? data.name : "",
      confectionData: data !== null ? data : "",
      errorConfection: false,
    });

    setOpenAlert(false);
  };

  const changeProducts = (data) => {
    setValues({
      ...values,
      products: data !== null ? data.id : "",
      productsData: data !== null ? data : "",
      productsModelData: "",
      productsModel: "",
      errorProductsModels: false,
      errorProducts: false,
    });

    setDisabledProductModels(data !== null ? false : true);
    setOpenAlert(false);
  };

  const changeProductsModels = (data) => {
    setValues({
      ...values,
      productsModel: data !== null ? data.id : "",
      productsModelData: data !== null ? data : "",
      errorProductsModels: false,
    });

    setOpenAlert(false);
  };

  const changeSizes = (data) => {
    setValues({
      ...values,
      sizes: data.length > 0 ? data : [],
      sizesData: data.length > 0 ? data : [],
      errorSizes: false,
    });

    setOpenAlert(false);
  };

  const handleAdd = () => {
    if (values.confection === "") {
      setValues({ ...values, errorConfection: true });
      setMensaje("Seleccione un insumo.");
      setOpenAlert(true);
      setError("error");
    } else if (values.quantity.length === 0) {
      setValues({ ...values, errorQuantity: true });
      setMensaje("Agregue una cantidad.");
      setOpenAlert(true);
      setError("error");
    } else {
      let includeID = rowsConfectionItem.filter(
        (vendor) => vendor.confection_item_id === values.confection
      );

      if (includeID.length === 0) {
        setRowsConfectionItem([
          ...rowsConfectionItem,
          {
            confection_item_id: values.confection,
            confectionName: values.confectionName,
            quantity: values.quantity,
          },
        ]);

        setValues({
          ...values,
          confection: "",
          confectionData: "",
          confectionName: "",
          quantity: "",
          errorConfection: false,
          errorQuantity: false,
        });
      } else {
        setValues({
          ...values,
          errorConfection: true,
        });
        setMensaje("Ya este insumo fué seleccionado.");
        setOpenAlert(true);
        setError("error");
      }
    }
  };

  const handleRemoveClick = (i) => {
    const list = [...rowsConfectionItem];
    list.splice(i, 1);
    setRowsConfectionItem(list);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>
                      Nueva composición
                    </div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
              
             

              

                  <Grid item xs={12} md={12}>
                    <br></br>
                    <center>
                      {" "}
                      <div className={classes.cardSubTitle}>
                        <b>SELECCIONAR INSUMOS</b>
                      </div>
                    </center>
                  </Grid>

                
                  <Grid item xs={12} sm={4}>
                    <NumberFormat
                      customInput={TextField}
                      label="Cantidad"
                      name="quantity"
                      value={values.quantity}
                      error={values.errorQuantity}
                      onChange={handleChange("quantity")}
                      decimalSeparator=","
                      allowNegative={false}
                      required
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      autoComplete="off"
                      inputProps={{
                        style: { textAlign: "center", fontSize: 16 },
                      }}
                      placeholder="0,00"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button
                      className={classes.buttonLeft}
                      onClick={handleAdd}
                      startIcon={<AddCircle />}
                    >
                      Agregar
                    </Button>
                  </Grid>
                  <br></br>

                  <Grid item xs={12} sm={12}>
                    <span className={classes.cardTitleDialog}>
                      <small>Insumos seleccionados:</small>
                    </span>
                    {rowsConfectionItem.length > 0 ? (
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableBody>
                          <TableCell component="th" scope="row">
                            <b>Insumo</b>{" "}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <center>
                              <b>Cantidad</b>{" "}
                            </center>
                          </TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          {rowsConfectionItem.map((row1, i1) => {
                            return (
                              <StyledTableRow>
                                <TableCell
                                  // align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.confectionName}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.quantity}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  <Delete
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => handleRemoveClick(i1)}
                                  />
                                </TableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    ) : (
                      <center>
                        <b style={{ color: "#C5C5C5" }}>
                          <br></br>No hay insumos.<br></br>
                        </b>
                      </center>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
