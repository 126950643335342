import React, { Fragment, useState, useContext, useEffect } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import axios from "axios";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import { Cancel, AddCircle, Delete } from "@material-ui/icons";
import NumberFormat from "react-number-format";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

//import LoadSizes from "components/services/LoadSizes";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function EditCompositionsAdmin(props) {
  const classes = useStyles();

  const [openAlert, setOpenAlert] = useState(false);
  const [mensaje, setMensaje] = useState("");
  const [error, setError] = useState("");
  const [rowsConfectionItem, setRowsConfectionItem] = useState([]);
  //const [disabledProductModels, setDisabledProductModels] = useState(true);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const [values, setValues] = useState({
    quantity: "",
    confection: "",
    confectionName: "",
    confectionData: "",
    products: props.data.product_id,
    productsData: {
      id: props.data.product_id,
      name: props.data.product_name,
    },
    productsModel: props.data.model_id,
    productsModelData: {
      id: props.data.model_id,
      name: props.data.model_name,
    },
    sizes: props.data.size_id,
    sizesData: {
      code: props.data.size_id,
      detailName: props.data.size_name,
    },
    itemsData: [],
    itemsDataDelete: [],
    itemsDataNew: [],
    errorQuantity: false,
    errorConfection: false,
    errorProducts: false,
    errorProductsModels: false,
    errorSizes: false,
  });

  useEffect(() => {
    axios
      .get(`${urlServices}compositions-details/composition/${props.data.id}`, {
        headers: {
          application: keyAplication,
          Authorization: "Bearer " + keyAuthorization,
        },
      })
      .then(
        (response) => {
          setRowsConfectionItem(response.data);
          setValues({
            ...values,
            itemsData: response.data,
          });
        },
        (error) => {
          setMensaje("Error de conexión, intente más tarde.");
          setOpenAlert(true);
          setError("error");
        }
      );
  }, []);

  const handleCloseDialog = (pro) => (event) => {
    props.exit();
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorQuantity: false,
    });
    setOpenAlert(false);
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setOpenAlert(false);

    /*if (values.products.length === 0) {
      setValues({ ...values, errorProducts: true });
      setMensaje("Seleccionar un producto.");
      setOpenAlert(true);
      setError("error");
    } else if (values.productsModel === "") {
      setValues({ ...values, errorProductsModels: true });
      setMensaje("Seleccionar un modelo.");
      setOpenAlert(true);
      setError("error");
    } else if (values.sizes.length === 0) {
      setValues({ ...values, errorSizes: true });
      setMensaje("Seleccionar una talla con modelo.");
      setOpenAlert(true);
      setError("error");
    } else */ if (rowsConfectionItem.length === 0) {
      setValues({ ...values, errorConfection: true });
      setMensaje("No hay ningún insumo agregado.");
      setOpenAlert(true);
      setError("error");
    } else {
      const dataValue = {
        product_id: values.products,
        model_id: values.productsModel,
        size_id: values.sizes,
      };

      axios
        .put(`${urlServices}compositions/${props.data.id}`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            if (values.itemsDataDelete.length > 0) {
              (async function () {
                await CompositionsDetailsDelete();
              })();
            }

            if (values.itemsDataNew.length > 0) {
              (async function () {
                await CompositionsDetails(response.data);
              })();
            }

            setError("success");
            setMensaje("Registro guardado con éxito.");
            setOpenAlert(true);

            props.callBackRefresh();
          },
          (error) => {
            setMensaje("Error de conexión, intente más tarde.");
            setOpenAlert(true);
            setError("error");
          }
        );
    }
  };

  const CompositionsDetails = (data) => {
    values.itemsDataNew.map((items2) => {
      const dataValue = {
        quantity: items2.quantity,
        confection_item_id: items2.confection_item_id,
        composition_id: data.id,
      };

      axios
        .post(`${urlServices}compositions-details`, dataValue, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {
            console.log(error);
          }
        );
    });
  };

  const CompositionsDetailsDelete = () => {
    values.itemsDataDelete.map((items) => {
      axios
        .delete(`${urlServices}compositions-details/${items.id}`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {},
          (error) => {}
        );
    });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };

  const changeConfection = (data) => {
    setValues({
      ...values,
      confection: data !== null ? data.id : "",
      confectionName: data !== null ? data.name : "",
      confectionData: data !== null ? data : "",
      errorConfection: false,
    });

    setOpenAlert(false);
  };

  /*const changeProducts = (data) => {
    setValues({
      ...values,
      products: data !== null ? data.id : "",
      productsData: data !== null ? data : "",
      productsModelData: "",
      productsModel: "",
      errorProductsModels: false,
      errorProducts: false,
    });

    setDisabledProductModels(data !== null ? false : true);
    setOpenAlert(false);
  };

  const changeProductsModels = (data) => {
    setValues({
      ...values,
      productsModel: data !== null ? data.id : "",
      productsModelData: data !== null ? data : "",
      errorProductsModels: false,
    });

    setOpenAlert(false);
  };

  const changeSizes = (data) => {
    setValues({
      ...values,
      sizes: data !== null ? data.code : "",
      sizesData: data !== null ? data : null,
      errorSizes: false,
    });

    setOpenAlert(false);
  };*/

  const handleAdd = () => {
    if (values.confection === "") {
      setValues({ ...values, errorConfection: true });
      setMensaje("Seleccione un insumo.");
      setOpenAlert(true);
      setError("error");
    } else if (values.quantity.length === 0) {
      setValues({ ...values, errorQuantity: true });
      setMensaje("Agregue una cantidad.");
      setOpenAlert(true);
      setError("error");
    } else {
      let includeID = rowsConfectionItem.filter(
        (vendor) => vendor.confection_item_id === values.confection
      );

      if (includeID.length === 0) {
        var dataIncluidsNew = [...values.itemsDataNew],
          dataValue = [
            {
              id: values.confection,
              confection_item_name: values.confectionName,
              quantity: values.quantity,
            },
          ];

        setRowsConfectionItem([
          ...rowsConfectionItem,
          {
            confection_item_id: values.confection,
            confection_item_name: values.confectionName,
            quantity: values.quantity,
          },
        ]);

        for (var i = 0; i < dataValue.length; i++) {
          var found = false;

          for (var j = 0; j < values.itemsData.length; j++) {
            // j < is missed;
            if (dataValue[i].id === values.itemsData[j].id) {
              found = true;
              break;
            }
          }
          if (found === false) {
            dataIncluidsNew.push({
              confection_item_id: dataValue[i].id,
              confection_item_name: dataValue[i].confection_item_name,
              quantity: dataValue[i].quantity,
            });
          }
        }

        setValues({
          ...values,
          confection: "",
          confectionData: "",
          confectionName: "",
          quantity: "",
          itemsDataNew: dataIncluidsNew,
          errorConfection: false,
          errorQuantity: false,
        });
      } else {
        setValues({
          ...values,
          errorConfection: true,
        });
        setMensaje("Ya este insumo fué seleccionado.");
        setOpenAlert(true);
        setError("error");
      }
    }
  };

  const handleRemoveClick = (i, row) => {
    const list = [...rowsConfectionItem],
      dataIncluidsDelete = [],
      dataIncluidNew = [];

    list.splice(i, 1);
    setRowsConfectionItem(list);

    for (var i0 = 0; i0 < values.itemsData.length; i0++) {
      var found0 = false;

      for (var j0 = 0; j0 < list.length; j0++) {
        // j < is missed;
        if (values.itemsData[i0].id === list[j0].id) {
          found0 = true;
          break;
        }
      }
      if (found0 === false) {
        dataIncluidsDelete.push({ id: values.itemsData[i0].id });
      }
    }

    values.itemsDataNew.map((items) => {
      if (items.confection_item_id !== row.confection_item_id) {
        dataIncluidNew.push(items);
      }
    });

    setValues({
      ...values,
      itemsDataDelete: dataIncluidsDelete,
      itemsDataNew: dataIncluidNew,
    });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog(true)}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        keepMounted
        scroll="body"
        style={{ zIndex: 100 }}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={1} className={classes.containerProfile}>
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2} style={{ padding: 10 }}>
                  <Grid item xs={12} sm={12}>
                    <div className={classes.titleCardFilter}>
                      Editar composición
                    </div>
                    <div className={classes.lineBottom}></div>
                    <br></br>
                  </Grid>
                  {/*
               

                  <Grid item xs={6} sm={6}>
                    <LoadSizes
                      value={values.sizesData}
                      refresh={changeSizes}
                      error={values.errorSizes}
                    />
                  </Grid>*/}

                  <Grid item xs={12} md={12}>
                    <center>
                      {" "}
                      <div className={classes.cardSubTitle}>
                        <b>SELECCIONAR INSUMOS</b>
                      </div>
                    </center>
                  </Grid>
                 
                  <Grid item xs={12} sm={4}>
                    <NumberFormat
                      customInput={TextField}
                      label="Cantidad"
                      name="quantity"
                      value={values.quantity}
                      error={values.errorQuantity}
                      onChange={handleChange("quantity")}
                      decimalSeparator=","
                      allowNegative={false}
                      required
                      fullWidth
                      variant="outlined"
                      margin="dense"
                      autoComplete="off"
                      inputProps={{
                        style: { textAlign: "center", fontSize: 16 },
                      }}
                      placeholder="0,00"
                    />
                  </Grid>

                  <Grid item xs={12} sm={2}>
                    <Button
                      className={classes.buttonLeft}
                      onClick={handleAdd}
                      startIcon={<AddCircle />}
                    >
                      Agregar
                    </Button>
                  </Grid>
                  <br></br>

                  <Grid item xs={12} sm={12}>
                    <span className={classes.cardTitleDialog}>
                      <small>Insumos seleccionados:</small>
                    </span>
                    {rowsConfectionItem.length > 0 ? (
                      <Table
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableBody>
                          <TableCell component="th" scope="row">
                            <b>Insumo</b>{" "}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <center>
                              <b>Cantidad</b>{" "}
                            </center>
                          </TableCell>
                          <TableCell component="th" scope="row"></TableCell>
                          {rowsConfectionItem.map((row1, i1) => {
                            return (
                              <StyledTableRow>
                                <TableCell
                                  // align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.confection_item_name}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  {row1.quantity}
                                </TableCell>
                                <TableCell
                                  align="center"
                                  component="th"
                                  scope="row"
                                >
                                  <Delete
                                    style={{
                                      cursor: "pointer",
                                      float: "right",
                                    }}
                                    onClick={() => handleRemoveClick(i1, row1)}
                                  />
                                </TableCell>
                              </StyledTableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    ) : (
                      <center>
                        <b style={{ color: "#C5C5C5" }}>
                          <br></br>No hay insumos.<br></br>
                        </b>
                      </center>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={12}>
                    <br></br>
                    <center>
                      <Button
                        type="submit"
                        className={classes.buttonSubmit}
                        onClick={submitForm}
                      >
                        Guardar
                      </Button>
                    </center>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Snackbar
              open={openAlert}
              autoHideDuration={6000}
              anchorOrigin={{ vertical: "top", horizontal: "center" }}
              onClose={handleClose}
            >
              <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
                <Alert
                  onClose={handleClose}
                  severity={error === "error" ? "error" : "success"}
                  elevation={6}
                  variant="filled"
                >
                  {mensaje}
                </Alert>
              </Slide>
            </Snackbar>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog(true)}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
