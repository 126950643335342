import React, { Fragment, useState } from "react";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { Cancel, KeyboardArrowRight } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function ResourcesData(props) {
  const classes = useStyles();

  const [values, setValues] = useState({
    requiredHours: "",
    yearsExperience: "",
    errorRequiredHours: false,
    errorYearsExperience: false,
  });

  const handleSend = () => {
    if (values.requiredHours.length === 0) {
      setValues({ ...values, errorRequiredHours: true });
    } else if (values.yearsExperience.length === 0) {
      setValues({ ...values, errorYearsExperience: true });
    } else {
      props.callback({
        save: 1,
        requiredHours: values.requiredHours,
        yearsExperience: values.yearsExperience,
        code: props.data[0].code,
        id: props.data[0].id,
        detailName: props.data[0].detailName,
        cat_id: props.data[0].cat_id,
      });
    }
  };

  const handleClose = () => {
    props.callback({
      save: 0,
      code: props.data[0].code,
      id: props.data[0].id,
      detailName: props.data[0].detailName,
      cat_id: props.data[0].cat_id,
    });
  };

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorYearsExperience: false,
      errorRequiredHours: false,
    });
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        //  onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers style={{ padding: 40, background: "#fff" }}>
          <div
            style={{
              background: "lavender",
              padding: 3,
              width: "98%",
              marginBottom: 10,
            }}
          >
            <KeyboardArrowRight className={classes.iconDefault} />{" "}
            <b>{props.data[0].detailName}</b>
          </div>
          <Grid
            container
            spacing={3}
            style={{
              paddingTop: "1%",
              paddingRight: "3%",
              paddingLeft: "3%",
            }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                name="requiredHours"
                value={values.requiredHours}
                error={values.errorRequiredHours}
                onChange={handleChange("requiredHours")}
                fullWidth
                required
                inputProps={{ maxLength: 5 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                label="Horas requeridas"
                margin="dense"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="yearsExperience"
                value={values.yearsExperience}
                error={values.errorYearsExperience}
                onChange={handleChange("yearsExperience")}
                fullWidth
                required
                inputProps={{ maxLength: 5 }}
                onInput={(e) => {
                  e.target.value = e.target.value.replace(/[^0-9]/g, "");
                }}
                label="Años de experiencia"
                margin="dense"
                autoComplete="off"
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <br></br>
              <center>
                {" "}
                <Button onClick={handleSend} className={classes.buttonSubmit}>
                  Guardar
                </Button>
              </center>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
