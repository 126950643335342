import React, {
  Fragment,
  useState,
  forwardRef,
  useContext,
  useEffect,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import { Search, Cancel, AllInclusive, AddCircle } from "@material-ui/icons";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import MaterialTable from "material-table";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import format from "date-fns/format";

import EnableCompositionsAdmin from "views/dialog/EnableCompositionsAdmin";
import DeleteCompositionsAdmin from "views/dialog/DeleteCompositionsAdmin";
import NewCompositionsAdmin from "views/dialog/NewCompositionsAdmin";
import ViewCompositionsAdmin from "views/dialog/ViewCompositionsAdmin";
import EditCompositionsAdmin from "views/dialog/EditCompositionsAdmin";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

export default function CompositionsListAdmin() {
  const classes = useStyles();
  const tableRef = React.createRef();

  const [idRow, setIdRow] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);
  const [openEdit, setopenEdit] = useState(false);
  const [openNew, setopenNew] = useState(false);
  const [openView, setopenView] = useState(false);

  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  const tableIcons = {
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  };

  const [state] = useState({
    columns: [
      {
        title: "Producto",
        field: "product_name",
        width: "30%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 13,
          // textAlign: "center",
        },
      },
      {
        title: "Modelo",
        field: "model_name",
        width: "20%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 13,
          // textAlign: "center",
        },
      },
      {
        title: "Talla",
        //field: "size_name",
        render: (rowData) =>
          rowData.size_id,
        width: "20%",
        cellStyle: {
          whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 13,
          // textAlign: "center",
        },
      },
      {
        title: "Registro",
        render: (rowData) => format(new Date(rowData.created_at), "dd-MM-yyyy"),
        width: "10%",
        cellStyle: {
          // whiteSpace: "nowrap",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 13,
          textAlign: "center",
        },
      },
      {
        title: "Estatus",
        // field: "status",
        render: (rowData) => {
          const text = rowData.status === 1 ? "ACTIVO" : "INACTIVO";
          const color = rowData.status === 1 ? "green" : "red";
          return <b style={{ color: color }}>{text}</b>;
        },
        width: "5%",
        cellStyle: {
          // whiteSpace: "nowrap",
          textAlign: "CENTER",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
      {
        title: "Insumos",
        render: (rowData) => (
          <Button
            className={classes.buttonSubmit1}
            onClick={() => setOpenViewStart(rowData)}
            startIcon={<Search style={{ fontSize: 14 }} />}
          >
            Insumos
          </Button>
        ),
        width: "1%",
        cellStyle: {
          textAlign: "center",
          border: "1px solid rgb(241, 241, 241)",
          fontSize: 12,
        },
      },
    ],
  });

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const RefreshTable = () => {
    tableRef.current && tableRef.current.onQueryChange();
  };

  const setOpenViewStart = (data) => {
    setIdRow(data);
    setopenView(true);
  };

  const handleCloseDelete = () => {
    setOpenDelete(false);
  };

  const handleCloseAdd = () => {
    setOpenAdd(false);
  };

  const handleCloseNew = () => {
    setopenNew(false);
  };

  const handleCloseEdit = () => {
    setopenEdit(false);
  };

  const handleCloseView = () => {
    setopenView(false);
  };

  let url = "";

  return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="info">
            <div className={classes.cardTitleWhite}>
              <AllInclusive className={classes.iconWhite} /> Listado de
              asiganación de insumos
            </div>
          </CardHeader>
          <CardBody>
            <Fragment>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <Button
                    className={classes.buttonRight}
                    onClick={(event) => {
                      setopenNew(true);
                    }}
                    startIcon={<AddCircle />}
                  >
                    Agregar composición
                  </Button>
                </Grid>
              </Grid>
              <MaterialTable
                title=""
                columns={state.columns}
                tableRef={tableRef}
                style={{
                  boxShadow: "unset",
                  border: "1px solid rgb(241, 241, 241)",
                }}
                data={(query) =>
                  new Promise((resolve, reject) => {
                    if (query.search === "") {
                      url = `${urlServices}compositions?limit=${
                        query.pageSize
                      }&page=${query.page + 1}`;
                    } else {
                      url = `${urlServices}compositions?limit=0&page=${
                        query.page + 1
                      }`;
                    }
                    fetch(url, {
                      method: "get",
                      headers: {
                        application: keyAplication,
                        Authorization: "Bearer " + keyAuthorization,
                      },
                    })
                      .then((response) => response.json())
                      .then((result) => {
                        resolve({
                          //data: result.data,
                          data:
                            result.data !== undefined
                              ? result.data.filter(function (obj) {
                                  return Object.keys(obj).some(function (key) {
                                    return obj[key]
                                      ? obj[key]
                                          .toString()
                                          .toLowerCase()
                                          .includes(query.search)
                                      : false;
                                  });
                                })
                              : [],
                          page: result.page - 1,
                          totalCount: result.total,
                        });
                      });
                  })
                }
                localization={{
                  pagination: {
                    labelRowsSelect: "Filas",
                    labelDisplayedRows: " {from}-{to} de {count}",
                    firstTooltip: "Primera página",
                    previousTooltip: "Previo",
                    nextTooltip: "Siguiente",
                    lastTooltip: "Ultima página",
                  },
                  toolbar: {
                    // nRowsSelected: '{0} row(s) selected',
                    searchTooltip: "Filtrar",
                    searchPlaceholder: "Buscar",
                  },
                  header: {
                    actions: " --- ",
                  },
                  body: {
                    emptyDataSourceMessage: "No hay datos.",
                    filterRow: {
                      filterTooltip: "Filtro",
                    },
                  },
                }}
                actions={[
                  /* {
                    icon: "search",
                    tooltip: "Ver",
                    onClick: (event, rowData) => {
                      setopenView(true);
                      setIdRow(rowData);
                    },
                  },*/
                  {
                    icon: "edit",
                    tooltip: "Editar",
                    onClick: (event, rowData) => {
                      setopenEdit(true);
                      setIdRow(rowData);
                    },
                  },
                  /*{
                            icon: "delete",
                            tooltip: "Eliminar",
                            onClick: (event, rowData) => {
                              setOpenDelete(true);
                              setIdRow(rowData);
                            },
                          },*/
                  (rowData1) => ({
                    icon: () => <Cancel />,
                    tooltip: "Deshabilitar",
                    onClick: (event, rowData) => {
                      setOpenDelete(true);
                      setIdRow(rowData);
                    },
                    hidden: rowData1.status === 1 ? false : true,
                  }),
                  (rowData2) => ({
                    icon: () => <AddCircle style={{ color: "green" }} />,
                    tooltip: "Habilitar",
                    onClick: (event, rowData) => {
                      setOpenAdd(true);
                      setIdRow(rowData);
                    },
                    hidden: rowData2.status === 0 ? false : true,
                  }),
                  {
                    icon: "refresh",
                    tooltip: "Refresh Data",
                    isFreeAction: true,
                    onClick: () =>
                      tableRef.current && tableRef.current.onQueryChange(),
                  },
                ]}
                icons={tableIcons}
                options={{
                  search: true,
                  padding: "default",
                  filtering: false,
                  actionsColumnIndex: -1,
                  pageSize: 10,
                  headerStyle: {
                    background: "#F2F2F2",
                    padding: "1%",
                    fontWeight: "bold",
                    textAlign: "center",
                  },
                  cellStyle: { border: "1px solid #DDDDDD" },
                }}
              />
            </Fragment>
          </CardBody>
        </Card>
      </GridItem>
      {openDelete ? (
        <DeleteCompositionsAdmin
          open={openDelete}
          exit={handleCloseDelete}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openAdd ? (
        <EnableCompositionsAdmin
          open={openAdd}
          exit={handleCloseAdd}
          id={idRow}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openNew ? (
        <NewCompositionsAdmin
          open={openNew}
          exit={handleCloseNew}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
      {openView ? (
        <ViewCompositionsAdmin
          open={openView}
          data={idRow}
          exit={handleCloseView}
        />
      ) : (
        ""
      )}
      {openEdit ? (
        <EditCompositionsAdmin
          open={openEdit}
          data={idRow}
          exit={handleCloseEdit}
          callBackRefresh={RefreshTable}
        />
      ) : (
        ""
      )}
    </GridContainer>
  );
}
