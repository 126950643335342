import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

// core components
import Grid from "@material-ui/core/Grid";
import Card from "components/Card/Card.js";
//import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import {
  // Store,
  // ViewModule,
  // HowToReg,
  // Place,
  Group,
  Apps,
} from "@material-ui/icons";
//import CountUp from "react-countup";
import { Animated } from "react-animated-css";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";


import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();
  const [openUsers, setopenUsers] = useState(false);
  const [openProduct, setopenProduct] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const handleOpenUsers = () => () => {
    setopenUsers(true);
  };

  const handleOpenProduct = () => () => {
    setopenProduct(true);
  };

  if (openProduct) {
    return <Redirect to="/admin/productlist" />;
  }

  if (openUsers) {
    return <Redirect to="/admin/users" />;
  }

  return (
    <div>
      <Grid container spacing={1}>
        {/* <Grid item xs={12} sm={12}>
          <center>
            {error ? (
              <Alert severity="error">Error en con la conexión</Alert>
            ) : (
              ""
            )}
          </center>
        </Grid>*/}
        {/*<Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <ViewModule />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  //onComplete={onComplete}
         // onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Store />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  //onComplete={onComplete}
          //onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <HowToReg />
              </CardIcon>
              <h3 className={classes.cardTitle}>
                <CountUp
                  className="account-balance"
                  start={0}
                  end={parseInt(0)}
                  duration={12}
                  useEasing={true}
                  //useGrouping={true}
                  // separator=" "
                  //decimals={4}
                  //decimal=","
                  //prefix="EUR "
                  //suffix=" left"
                  //onComplete={onComplete}
          //onStart={onStart}
                />
              </h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Place />
              </CardIcon>
              <h3 className={classes.cardTitle}>----</h3>
              <p className={classes.cardCategory}>EN DESARROLLO ...</p>
            </CardHeader>
          </Card>
        </Grid>*/}
      </Grid>

      <div className={classes.carDashboard}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12}>
            <div className={classes.titleCardFilter}>
              Reporte de cortes disponibles
            </div>
            <div className={classes.lineBottom}></div>
            <br></br>
          </Grid>        
          <Grid item xs={12} md={4}>
            <Grid container>
              <Grid item xs={12} md={12}>
                <br></br>
                <Animated
                  animationIn="bounceIn"
                  animationInDuration={2000}
                  isVisible={true}
                >
                  <Card>
                    {" "}
                    <ListItem button onClick={handleOpenUsers()}>
                      <CardIcon color="success">
                        <Group
                          fontSize="large"
                          className={classes.iconWhite}
                        ></Group>
                      </CardIcon>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        color="textPrimary"
                      >
                        Listado de usuarios
                      </Typography>
                    </ListItem>
                  </Card>
                </Animated>
              </Grid>
              <Grid item xs={12} md={12}>
                <Animated
                  animationIn="bounceIn"
                  animationInDuration={2000}
                  isVisible={true}
                >
                  <Card>
                    {" "}
                    <ListItem button onClick={handleOpenProduct()}>
                      <CardIcon color="warning">
                        <Apps
                          fontSize="large"
                          className={classes.iconWhite}
                        ></Apps>
                      </CardIcon>
                      <Typography
                        variant="subtitle2"
                        align="center"
                        color="textPrimary"
                      >
                        Listado de productos
                      </Typography>
                    </ListItem>
                  </Card>
                </Animated>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
