import React, { Fragment, useEffect, useState, useContext } from "react";
import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import { UrlServicesContext } from "../UrlServicesContext";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";

export default function LoadAnnualBilling2(props) {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(false);
  const [isLoadedd, setIsLoadedd] = useState(false);

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    const dataCat = {
      catalogs: [
        {
          detailName: "catAnnualBilling",
          codeExclusion: "0",
        },
      ],
    };

    const consultar = async () => {
      await axios
        .post(`${urlServices}catalogsDetails/getMultiplesCatalogs`, dataCat, {
          headers: {
            application: keyAplication,
          },
        })
        .then(
          (response) => {
            setResults(response.data.catalogs.catAnnualBilling);
            setIsLoadedd(true);
          },
          (error) => {
            console.log(error);
            setResults(null);
            setError(true);
            setIsLoadedd(false);
          }
        );
    };
    consultar();
  }, [urlServices, keyAplication]);

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>¿CUANTO ESTÁS FACTURANDO ANUL?</b>
        </Alert>
      ) : (
        ""
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.callback(newValue);
          }}
          getOptionLabel={(option) => option.detailName || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="¿Cuánto esperas facturar durante este año?"
              variant="standard"
              error={props.error}
              required
              inputProps={{
                ...params.inputProps,
                autoComplete: "off", // disable autocomplete and autofill
              }}
            />
          )}
        />
      )}
    </Fragment>
  );
}
