import React, { Fragment, useState, useEffect, useContext } from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import axios from "axios";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import { Visibility, VisibilityOff } from "@material-ui/icons/";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import Slide from "@material-ui/core/Slide";
import FormControl from "@material-ui/core/FormControl";
import InputAdornment from "@material-ui/core/InputAdornment";
import InputLabel from "@material-ui/core/InputLabel";
import Input from "@material-ui/core/Input";
import FormHelperText from "@material-ui/core/FormHelperText";
import Avatar from "@material-ui/core/Avatar";
import FileBase64 from "react-file-base64";
import Resizer from "react-image-file-resizer";
import NumberFormat from "react-number-format";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      "100%",
      "100%",
      "JPEG",
      18,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

export default function FormProfile(props) {
  const classes = useStyles();
  const [openBackdrop, setoOpenBackdrop] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [mensaje, setMensaje] = useState("");
  const [errorServer, setErrorServer] = useState(false);
  const [error, setError] = useState("");
  const [loader, setLoader] = useState(true);

  let idUser = Number(localStorage.getItem("id"));
  let keyAuthorization = localStorage.getItem("Session");

  const [values, setValues] = useState({
    name: "",
    lastname: "",
    cedula: "",
    username: "",
    address: "",
    phone: "",
    email: "",
    password: "",
    showPassword: false,
    Imgbase64: "",
    AvatarUpload: "",
    errorCedula: false,
    errorName: false,
    errorLastName: false,
    errorAddress: false,
    errorPhone: false,
  });

  const { urlServices, keyAplication, urlLocalMediaProfile } =
    useContext(UrlServicesContext);

  useEffect(() => {
    const fetchData = async () => {
      await axios
        .get(`${urlServices}/users/${idUser}`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            let imgProfile =
              response.data.picture === null
                ? ""
                : urlLocalMediaProfile + response.data.picture;

            setValues({
              ...values,
              name: response.data.name,
              lastname:
                response.data.last_name === null ? "" : response.data.last_name,
              cedula: response.data.document_id,
              username: response.data.username,
              email: response.data.email,
              AvatarUpload: imgProfile,
              address:
                response.data.address === null ? "" : response.data.address,
              phone: response.data.phone === null ? "" : response.data.phone,
            });
            setLoader(false);
          },
          (error) => {
            console.log(error);
            setErrorServer(true);
            setLoader(false);
          }
        );
    };
    fetchData();
  }, [idUser, urlServices, urlLocalMediaProfile, keyAplication]);

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      errorName: false,
      errorLastName: false,
      errorCedula: false,
      errorAddress: false,
      errorPhone: false,
    });
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitForm = (e) => {
    e.preventDefault();
    setError("");
    setErrorServer(false);

    if (values.name === "") {
      setValues({ ...values, errorName: true });
      setMensaje('El campo "Nombre" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.lastname === "") {
      setValues({ ...values, errorLastName: true });
      setMensaje('El campo "Apellido" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.cedula === "") {
      setValues({ ...values, errorCedula: true });
      setMensaje('El campo "Documento" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.phone === "") {
      setValues({ ...values, errorPhone: true });
      setMensaje('El campo "Teléfono" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else if (values.address === "") {
      setValues({ ...values, errorAddress: true });
      setMensaje('El campo "Dirección" es obligatorio.');
      setOpenAlert(true);
      setError("error");
      setoOpenBackdrop(false);
    } else {
      setoOpenBackdrop(true);

      const data = {
        name: values.name,
        last_name: values.lastname,
        document_id: values.cedula.toString(),
        address: values.address,
        phone: values.phone.toString(),
      };

      if (values.password.length !== 0) {
        data.password = values.password;
      }

      if (values.Imgbase64.length !== 0) {
        data.picture = values.Imgbase64;
      }

      axios
        .put(`${urlServices}users/${idUser}`, data, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            setoOpenBackdrop(false);
            setError("success");
            setMensaje("Datos guardados con exito.");
            setOpenAlert(true);
          },
          (error) => {
            console.log(error);
            setErrorServer(true);
            setoOpenBackdrop(false);
          }
        );
    }
  };

  const uploadSingleFile = async (e) => {
    let image = "",
      outfile = "";

    if (e.length > 0 && e.length <= 1) {
      if (Number(e[0].file.size) > 500000) {
        // SI ES MAYOR A 500KB LE BAJAMOS EL PESO
        image = await resizeFile(e[0].file);
        // const newFile = dataURIToBlob(image);
        outfile = image;
      } else {
        outfile = e[0].base64;
      }
      setValues({ ...values, Imgbase64: outfile });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenAlert(false);
  };
  if (loader) {
    return (
      <Fragment>
        <Grid container spacing={2} className={classes.containerProfile}>
          <Grid item xs={12} sm={6}>
            <Box sx={{ width: "100%" }}>
              <Skeleton width="100%" height={300} />
              <Skeleton width="100%" height={40} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box sx={{ width: "100%", marginTop: "20%" }}>
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
              <Skeleton width="100%" height={40} />
            </Box>
          </Grid>
        </Grid>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Grid container spacing={2} className={classes.containerProfile}>
          <Grid item xs={12} sm={12}>
            {errorServer ? (
              <Alert severity="error">Error en la consulta al servidor</Alert>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3} style={{ padding: "3%" }}>
              <Grid item xs={12} sm={12} className={classes.containerImg}>
                <center>
                  <Avatar
                    alt="Avatar"
                    src={
                      values.Imgbase64 === ""
                        ? values.AvatarUpload
                        : values.Imgbase64
                    }
                    className={classes.imgUserProfile}
                  />
                  <br></br>
                  <label className={classes.buttonSubmit2}>
                    <FileBase64 multiple={true} onDone={uploadSingleFile} />
                    <i class="fa fa-cloud-upload"></i> Seleccionar foto
                  </label>
                </center>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="name"
                    value={values.name}
                    error={values.errorName}
                    onChange={handleChange("name")}
                    fullWidth
                    required
                    label="Nombre"
                    margin="dense"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="lastname"
                    value={values.lastname}
                    error={values.errorLastName}
                    onChange={handleChange("lastname")}
                    fullWidth
                    required
                    label="Apellido"
                    margin="dense"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="cedula"
                  value={values.cedula}
                  onChange={handleChange("cedula")}
                  //prefix={"$"}
                  // thousandSeparator="."
                  //  decimalSeparator=","
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: 16,
                      marginTop: 25,
                    },
                  }}
                  label="Documento"
                  fullWidth
                  autoComplete="off"
                  margin="dense"
                  helperText="Cédula, RUC o pasaporte."
                  error={values.errorCedula}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <NumberFormat
                  customInput={TextField}
                  name="phone"
                  value={values.phone}
                  onChange={handleChange("phone")}
                  //prefix={"$"}
                  // thousandSeparator="."
                  //  decimalSeparator=","
                  allowNegative={false}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: 16,
                      marginTop: 25,
                    },
                  }}
                  label="Teléfono"
                  fullWidth
                  autoComplete="off"
                  margin="dense"
                  error={values.errorPhone}
                />
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="address"
                    value={values.address}
                    error={values.errorAddress}
                    onChange={handleChange("address")}
                    fullWidth
                    required
                    label="Dirección"
                    margin="dense"
                    autoComplete="off"
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Grid container spacing={3} style={{ padding: "3%" }}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>
                  Datos de inicio de sesión
                </div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="username"
                    value={values.username}
                    //onChange={handleChange("usuario")}
                    fullWidth
                    //required
                    //inputProps={{ maxLength: 100 }}
                    label="Usuario"
                    margin="dense"
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%" }}>
                  <TextField
                    name="email"
                    value={values.email}
                    // onChange={handleChange("usuario")}
                    fullWidth
                    // inputProps={{ maxLength: 100 }}
                    label="Email"
                    margin="dense"
                    disabled
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={12}>
                <FormControl style={{ width: "100%", marginTop: "1%" }}>
                  <InputLabel htmlFor="standard-adornment-password">
                    Nueva Contraseña
                  </InputLabel>
                  <Input
                    type={values.showPassword ? "text" : "password"}
                    value={values.password}
                    onChange={handleChange("password")}
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <FormHelperText id="standard-weight-helper-text">
                    Si desea cambiar la contraseña, ingrese una nueva en este
                    campo.
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  type="submit"
                  className={classes.buttonSubmit}
                  onClick={submitForm}
                >
                  Guardar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Backdrop
          style={{ zIndex: "9999", color: "#FFFFFF" }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Snackbar
          open={openAlert}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleClose}
        >
          <Slide direction="up" in={openAlert} mountOnEnter unmountOnExit>
            <Alert
              onClose={handleClose}
              severity={error === "error" ? "error" : "success"}
              elevation={6}
              variant="filled"
            >
              {mensaje}
            </Alert>
          </Slide>
        </Snackbar>
      </Fragment>
    );
  }
}
