import React, { useState } from "react";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import ExitToApp from "@material-ui/icons/ExitToApp";
// core components
import Button from "components/CustomButtons/Button.js";
import PopupLogout from "views/dialog/PopupLogout";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import ButtonGroup from "@material-ui/core/ButtonGroup";

import styles from "assets/jss/material-dashboard-react/components/headerLinksStyle.js";

const useStyles = makeStyles(styles);

export default function AdminNavbarLinks() {
  const classes = useStyles();
  const [popupLogout, setpopupLogout] = React.useState(false);

  const handleOpenpopupLogout = () => {
    setpopupLogout(true);
  };

  const handleClosepopupLogout = () => {
    setpopupLogout(false);
  };

  return (
    <div className={classes.manager}>
      <GridContainer style={{ marginRight: "15px" }}>
        <GridItem xs={12} md={6}>
          <div className={classes.linkText}>
            Bienvenid@ <b>{localStorage.getItem("allname")}</b>
          </div>
        </GridItem>
        <GridItem xs={12} md={6}>
          <ButtonGroup
            aria-label="outlined primary button group"
            color="default"
          >
            <Button
              size={"sm"}
              color={"transparent"}
              // onClick={handleClickProfile}
              onClick={handleOpenpopupLogout}
              className={classes.buttonSubmit2}
            >
              <ExitToApp className={classes.icons} />
              Salir
            </Button>
          </ButtonGroup>
        </GridItem>
      </GridContainer>
      {popupLogout ? (
        <PopupLogout open={popupLogout} exit={handleClosepopupLogout} />
      ) : (
        ""
      )}
    </div>
  );
}
