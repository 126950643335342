import React, { Fragment, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router-dom";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import { Animated } from "react-animated-css";
import mail from "assets/img/undraw_towing_6yy4.png";
import { Cancel } from "@material-ui/icons";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function PopupLogout(props) {
  const classes = useStyles();
  const [open] = useState(props.open);
  const [returnLogin, setReturnLogin] = React.useState(false);

  const handleClose = () => {
    props.exit();
  };

  const handleLogout = () => {
    setTimeout(() => {
      localStorage.clear();
      setReturnLogin(true);
    }, 200);
  };

  if (returnLogin) {
    return <Redirect to="/" />;
  }

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers>
          <Grid container className={classes.root}>
            <Grid item xs={12} sm={12}>
              <Animated
                animationIn="fadeIn"
                animationInDuration={1000}
                isVisible={true}
              >
                <center>
                  <img
                    src={mail}
                    alt="validate"
                    style={{
                      width: "110px",
                    }}
                  />
                  <div className={classes.titleCardFilter}>
                    ¿Desea cerrar sesión?
                  </div>

                  <Button
                    onClick={handleLogout}
                    className={classes.buttonSubmit2}
                  >
                    Cerrar sesión
                  </Button>
                </center>
              </Animated>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
