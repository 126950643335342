import React, { Fragment } from "react";
// core components
import { makeStyles } from "@material-ui/core/styles";

import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Grid from "@material-ui/core/Grid";

import creemos from "assets/img/creemos.png";
import logo from "assets/img/logoAmazonia.png";
import FormIntercambia from "views/Form/FormIntercambia";

import { headerStyle } from "assets/jss/material-dashboard-react/components/headerStyle.js";

const useStyles = makeStyles(headerStyle);

export default function Form() {
  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <AppBar className={classes.appBarPage}>
            <Toolbar>
              <Grid container>
                <Grid item xs={3} md={3}>
                  <img
                    src={logo}
                    alt="validate"
                    style={{
                      width: 100,
                      marginLeft: 30,
                      objectFit: "cover",
                    }}
                  />
                </Grid>
                <Grid item xs={9} md={9}>
                  <img
                    src={creemos}
                    alt="validate"
                    style={{
                      width: 180,
                      marginTop: 15,
                      objectFit: "cover",
                      float: "right",
                    }}
                  />
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
        </GridItem>
        <GridItem xs={12} sm={2} md={2}></GridItem>
        <GridItem xs={12} sm={8} md={8}>
          <Fragment>
            <div className={classes.titleForm}>REGISTRO PRINCIPAL</div>
            <FormIntercambia />
          </Fragment>
        </GridItem>
        <GridItem xs={12} sm={2} md={2}></GridItem>
      </GridContainer>
    </div>
  );
}
