import React, {
  Fragment,
  useContext,
  useEffect,
  forwardRef,
  useState,
} from "react";
import { UrlServicesContext } from "components/UrlServicesContext";

import PropTypes from "prop-types";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import format from "date-fns/format";
import Alert from "@material-ui/lab/Alert";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";
import { Cancel } from "@material-ui/icons";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import Typography from "@material-ui/core/Typography";
import TableRow from "@material-ui/core/TableRow";
import Slide from "@material-ui/core/Slide";
import axios from "axios";

import { cardBodyStyle } from "assets/jss/material-dashboard-react/components/cardBodyStyle";

const useStyles = makeStyles(cardBodyStyle);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
}))(TableRow);

function createData(name, data) {
  return { name, data };
}

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ViewWeeklyAdmin(props) {
  const classes = useStyles();
  const [loader, setLoader] = useState(true);
  const [idRow, setRows] = useState([]);
  const [error, setError] = useState(false);
  const [userName, setUserName] = useState("");
  let keyAuthorization = localStorage.getItem("Session");

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  let data = props.id;

  const color = (text) => {
    let textColor = "",
      color = "";

    if (text === 1) {
      textColor = "ACTIVO";
      color = "green";
    } else {
      textColor = "INACTIVO";
      color = "red";
    }

    return <b style={{ color: color }}>{textColor}</b>;
  };

  const rows = [
    createData("Nº", data.id),
    createData("Descripción", data.description),
    createData(
      "Día de inicio",
      format(new Date(data.starts_at), "dd-MM-yyyy kk:mm:ss")
    ),
    createData(
      "Día Fin",
      format(new Date(data.ends_at), "dd-MM-yyyy kk:mm:ss")
    ),
    createData(
      "Fecha registro",
      format(new Date(data.created_at), "dd-MM-yyyy kk:mm:ss")
    ),
    createData("Registrado por:", userName),
    createData("Estatus", color(data.status)),
  ];

  useEffect(() => {
    const consultar = () => {
      axios
        .get(`${urlServices}weekly-orders/${data.id}`, {
          headers: {
            application: keyAplication,
            Authorization: "Bearer " + keyAuthorization,
          },
        })
        .then(
          (response) => {
            if (response.status === 200) {
              setRows(response.data.weeklyOrderDetail);
              setUserName(response.data.user_name);
              setLoader(false);
            } else {
              setRows([]);
              setLoader(false);
            }
          },
          (error) => {
            console.log(error);
            setRows([]);
            setError(true);
          }
        );
    };
    consultar();
  }, [data.id, keyAplication, urlServices, keyAuthorization]);

  const handleCloseDialog = () => {
    props.exit();
  };

  let totalQuantity = 0;

  return (
    <Fragment>
      <Dialog
        fullWidth
        maxWidth="sm"
        onClose={handleCloseDialog}
        aria-describedby="alert-dialog-slide-description"
        open={props.open}
        TransitionComponent={Transition}
        keepMounted
        scroll="body"
        tabIndex={-1}
        PaperProps={{
          classes: { root: classes.DialogStyle },
        }}
      >
        <DialogContent dividers className={classes.styleDialogContent}>
          <Fragment>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <div className={classes.titleCardFilter}>Registro</div>
                <div className={classes.lineBottom}></div>
              </Grid>
              <Grid item xs={12} md={12}>
                {error ? (
                  <Alert severity="error">
                    Error en la consulta con sel servidor.
                  </Alert>
                ) : (
                  ""
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <TableContainer>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableBody>
                      {rows.map((row) => (
                        <StyledTableRow key={row.name}>
                          <TableCell component="th" scope="row">
                            <b>{row.name}</b>
                          </TableCell>
                          <TableCell>{row.data}</TableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid item xs={12} md={12}>
                <span className={classes.cardTitleDialog}>
                  <center>
                    {" "}
                    <small>PTODUCTOS SELECCIONADOS</small>
                  </center>
                </span>
                {loader ? (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12}>
                      <Box sx={{ width: "100%" }}>
                        <Skeleton width="100%" height={100} />
                      </Box>
                    </Grid>
                  </Grid>
                ) : (
                  <TableContainer>
                    <Table size="small" aria-label="a dense table">
                      <TableBody>
                        <TableCell component="th" scope="row">
                          <center>
                            <b>Producto</b>{" "}
                          </center>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <center>
                            <b>Cantidad</b>{" "}
                          </center>
                        </TableCell>
                        {idRow.map((row, index) => {
                          totalQuantity += row.quantity;
                          return (
                            <StyledTableRow key={row.id}>
                              <TableCell>{row.product_name}</TableCell>
                              <TableCell>
                                <center>{row.quantity}</center>
                              </TableCell>
                            </StyledTableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <span className={classes.cardTitleDialog}>
                  <small style={{ float: "right", marginRight: 65 }}>
                    TOTAL:{" "}
                    {totalQuantity
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}
                  </small>
                </span>
              </Grid>
            </Grid>
          </Fragment>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>
            <Cancel />
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
