import React, { Fragment, useEffect, useState, useContext } from "react";
import { UrlServicesContext } from "../../components/UrlServicesContext";

import Alert from "@material-ui/lab/Alert";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import axios from "axios";

export default function LoadProfile(props) {
  const [results, setResults] = useState([]);
  const [error, setError] = useState(false);
  const [isLoadedd, setIsLoadedd] = useState(false);

  const { urlServices, keyAplication } = useContext(UrlServicesContext);

  useEffect(() => {
    const data = {
      catalogs: [
        {
          detailName: "catProfile",
          codeExclusion: "0",
        },
      ],
    };

    axios
      .post(`${urlServices}catalogsDetails/getMultiplesCatalogs`, data, {
        headers: {
          application: keyAplication,
        },
      })
      .then(
        (response) => {
          setResults(response.data.catalogs.catProfile);
          setIsLoadedd(true);
        },
        (error) => {
          console.log(error);
          setError(true);
        }
      );
  }, [urlServices, keyAplication]);

  return (
    <Fragment>
      {error ? (
        <Alert severity="error">
          Error en la consulta de <b>PERFILES</b>
        </Alert>
      ) : (
        ""
      )}
      {!isLoadedd ? (
        <Fragment>
          <Grid item xs={12} sm={12}>
            <center>
              <Box sx={{ width: "100%" }}>
                <Skeleton width="100%" height={35} />
              </Box>
            </center>
          </Grid>
        </Fragment>
      ) : (
        <Autocomplete
          id="combo-box-demo"
          options={results}
          value={props.value}
          onChange={(event, newValue) => {
            props.refresh(newValue);
          }}
          getOptionLabel={(option) => option.detailName || ""}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Perfiles"
              variant="standard"
              error={props.error}
              required
            />
          )}
        />
      )}
    </Fragment>
  );
}
