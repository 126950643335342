/*!

=========================================================
* Material Dashboard React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// @material-ui/icons
import { Remove } from "@material-ui/icons";

// core components/views for Admin layout
import DashboardAdminUser from "views/Dashboard/DashboardAdminUserDressMaker";
import FormProfile from "views/AdminUserCut/FormProfile";
import ProductsCompleteListAdmin from "views/Admin/ProductsCompleteListAdmin.js";
import CompositionsListAdmin from "views/Admin/CompositionsListAdmin.js";
import AssignmentsDressmakersList from "views/Admin/AssignmentsDressmakersList.js";

const dashboardRoutes = [
  {
    id: 0,
    path: "/dashboard",
    name: "Inicio",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: DashboardAdminUser,
    layout: "/adminuserdressmaker",
    submenu: [],
  },
  {
    id: 0,
    subId: "",
    path: "/products",
    name: "Productos",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: ProductsCompleteListAdmin,
    layout: "/adminuserdressmaker",
    submenu: [],
  },
  {
    id: 1,
    subId: "",
    path: "",
    name: "Confeccionista",
    rtlName: "",
    icon: Remove,
    component: "",
    layout: "/adminuserdressmaker",
    submenu: [
      {
        subId: 1,
        name: " Asignación insumos",
        path: "/compositionslist",
      },
      {
        subId: 1,
        name: "Asignación confeccionista",
        path: "/dressmaker",
      },
      {
        subId: 1,
        name: "Listado Asignación",
        path: "/dressmakerlist",
      },
    ],
  },
  {
    // SUBMENU CONTROL DE CORTE ASIGNACION CONFECCIONISTA

    id: "",
    subId: 1,
    path: "/compositionslist",
    name: "",
    rtlName: "",
    icon: "",
    component: CompositionsListAdmin,
    layout: "/adminuserdressmaker",
    submenu: [],
  },
 
  {
    // SUBMENU ASIGNACION CONFECCIONISTA LIST
    id: "",
    subId: 1,
    path: "/dressmakerlist",
    name: "",
    rtlName: "",
    icon: "",
    component: AssignmentsDressmakersList,
    layout: "/adminuserdressmaker",
    submenu: [],
  },
  {
    id: 0,
    path: "/profile",
    name: "Mi perfil",
    rtlName: "لوحة القيادة",
    icon: Remove,
    component: FormProfile,
    layout: "/adminuserdressmaker",
    submenu: [],
  },
];

export default dashboardRoutes;
